import React, { useState } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useGetList } from 'react-admin';

import { useDebounce } from '@hooks/useDebounce';
import LoadingAnimation from '@components/svgs/loading_animation';
import { MissionIcon } from '@components/icons';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import MissionCard from './components/card';

export const MissionList = () => {
  const [filterKey, setFilterKey] = useState<string>('');
  const [otherFilters, setOtherFilters] = useState<any>({});
  const debouncedFilterKey = useDebounce(filterKey, 500);

  const {
    data: missions,
    isLoading,
    pageInfo,
  } = useGetList('missions', {
    pagination: { page: 1, perPage: 20 },
    sort: { field: 'name', order: 'ASC' },
    filter: {
      name: debouncedFilterKey || undefined,
      ...otherFilters,
    },
  });

  /**
   * Render a filter tag
   * @param label
   * @param filterKey
   * @param filterValue
   * @returns {JSX.Element}
   */
  const renderFilterTag = (
    label: string,
    filterKey: string,
    filterValue: undefined | null | string
  ) => (
    <div
      style={{
        ...styles.filter,
        ...(otherFilters[filterKey] === filterValue &&
        otherFilters.hasOwnProperty(filterKey)
          ? styles.filterSelected
          : {}),
      }}
      onClick={() => setOtherFilters({ [filterKey]: filterValue })}
    >
      {label}
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card style={{ width: '100%', maxWidth: 1200 }}>
        <CardHeader title="Missions" avatar={<MissionIcon />} />
        <CardContent>
          <input
            type="text"
            onChange={event => setFilterKey(event.target.value)}
            style={styles.filterInput}
            placeholder="Filter by name..."
            autoFocus
          />
          {missions && missions.length > 5 && (
            <div style={styles.filters}>
              Filter by:
              {renderFilterTag('all', 'outcome', undefined)}
              {renderFilterTag('📑 draft', 'publishedAt', null)}
              {renderFilterTag('🔄 ongoing', 'outcome', null)}
              {renderFilterTag('⏸️ on hold', 'outcome', 'ON_HOLD')}
              {renderFilterTag('🥳 won', 'outcome', 'SUCCESS')}
              {renderFilterTag('😭 lost', 'outcome', 'FAILURE')}
            </div>
          )}
          <div style={styles.missions}>
            {missions?.map(mission => (
              <MissionCard
                key={mission.id}
                mission={mission}
                maxWith={'22%'}
                withStartedAt
              />
            ))}
          </div>
          {isLoading && <LoadingAnimation />}
          {!isLoading && missions && missions.length === 0 && (
            <div>No missions found</div>
          )}
          {!isLoading && pageInfo && pageInfo.hasNextPage && (
            <div style={styles.helper}>
              🔍 More missions are available, use the search bar to refine your search.
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const styles: any = {
  filterInput: {
    width: '100%',
    padding: SpacingStyle.normal,
    marginBottom: SpacingStyle.big,
    border: `1px solid ${Colors.Grey[400]}`,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.Grey[25],
    fontSize: FontStyle.sizeSmall,
  },
  missions: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: SpacingStyle.big,
  },
  helper: {
    marginTop: SpacingStyle.big,
    fontSize: FontStyle.sizeSmall,
    color: Colors.Grey[400],
    textAlign: 'center',
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.small,
    marginBottom: SpacingStyle.normal,
  },
  filter: {
    padding: `${SpacingStyle[2]}px ${SpacingStyle[8]}px`,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.Magenta[100],
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 500,
    cursor: 'pointer',
  },
  filterSelected: {
    backgroundColor: Colors.Magenta[700],
    color: Colors.White.primary,
  },
};
