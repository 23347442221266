import React, { useCallback, useState } from 'react';
import { Button, useDataProvider, useRecordContext, useRefresh } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import EditIcon from '@mui/icons-material/Edit';
import { MissionType } from 'types/mission';
import { MissionStoryType } from 'types/mission_story';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { StoryIcon } from '@components/icons';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

type PopupMenuProps = {
  missionStory?: MissionStoryType | null;
  missionStoriesCount?: number;
  onClose: () => void;
};
const PopupMenu = ({ missionStory, missionStoriesCount, onClose }: PopupMenuProps) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const { canUpdate, canDelete } = useRoleBasedPermissions();

  /**
   * Upgrade the position of the mission story
   */
  const handleUpgradePosition = useCallback(async () => {
    if (!missionStory) {
      return;
    }
    await dataProvider.upgradeMissionStoryPosition(missionStory.id);
    refresh();
    onClose();
  }, [dataProvider, refresh, missionStory, onClose]);

  /**
   * Downgrade the position of the mission story
   */
  const handleDowngradePosition = useCallback(async () => {
    if (!missionStory) {
      return;
    }
    await dataProvider.downgradeMissionStoryPosition(missionStory.id);
    refresh();
    onClose();
  }, [dataProvider, refresh, missionStory, onClose]);

  if (!missionStory) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1000,
      }}
    >
      {/* Background */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          cursor: 'pointer',
          backgroundColor: Colors.White.transparent.light,
          backdropFilter: 'blur(5px)',
        }}
        onClick={onClose}
      />

      {/* Popup */}
      <div
        style={{
          position: 'absolute',
          top: '24%',
          left: '50%',
          transform: 'translate(-50%)',
          width: 240,
          minHeight: 200,
          backgroundColor: Colors.White.primary,
          boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          borderRadius: BorderStyle.Radius.big,
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: SpacingStyle.normal,
          padding: SpacingStyle.normal,
        }}
      >
        <div style={styles.story}>
          <img src={missionStory?.video?.posterUrl} style={styles.storyPoster} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            href={missionStory.video?.videoUrl}
            target="_blank"
            label="Play video"
            variant="outlined"
            startIcon={<PlayArrowIcon />}
            size="large"
          />
          {canUpdate('missions_stories') && (
            <>
              <Button
                href={`#/videos/${missionStory.videoId}/show/subtitles-beta`}
                label="Edit subtitles"
                variant="outlined"
                startIcon={<SubtitlesIcon />}
              />
              <Button
                onClick={handleDowngradePosition}
                variant="outlined"
                label="Move story up"
                disabled={missionStory.position === 1}
                startIcon={<ArrowCircleUpIcon />}
              />
              <Button
                onClick={handleUpgradePosition}
                variant="outlined"
                label="Move story down"
                disabled={missionStory.position === missionStoriesCount}
                startIcon={<ArrowCircleDownIcon />}
              />
            </>
          )}
          {canDelete('missions_stories') && (
            <Button
              href={`#/missions_stories/${missionStory.id}`}
              label="Edit / delete story"
              variant="outlined"
              startIcon={<EditIcon />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const StoriesCard = () => {
  const mission: MissionType = useRecordContext();
  const [selectedStory, setSelectedStory] = useState<MissionStoryType | null>(null);
  const { canCreate } = useRoleBasedPermissions();

  return (
    <Card>
      <PopupMenu
        missionStory={selectedStory}
        missionStoriesCount={mission?.stories?.length || 0}
        onClose={() => setSelectedStory(null)}
      />
      <CardHeader title="Stories" avatar={<StoryIcon />} />
      <CardContent style={styles.stories}>
        {mission.stories?.map(story => (
          <div
            key={story.id}
            style={styles.story}
            onClick={() => setSelectedStory(story)}
          >
            {story?.video?.posterUrl ? (
              <img src={story?.video?.posterUrl} style={styles.storyPoster} />
            ) : (
              '?'
            )}
            {canCreate('missions_stories') && (
              <div style={styles.editButton}>
                <MoreHorizIcon color="inherit" style={{ width: 14, height: 14 }} />
              </div>
            )}
          </div>
        ))}
        {canCreate('missions_stories') && (
          <a
            style={{ ...styles.story, ...styles.addStory }}
            href={`#/missions_stories/video_studio?missionId=${mission.id}`}
          >
            <AddIcon />
          </a>
        )}
      </CardContent>
    </Card>
  );
};

const styles: any = {
  stories: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.normal,
    flexWrap: 'wrap',
  },
  story: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: 60,
    backgroundColor: Colors.Magenta.primary,
    color: Colors.White.primary,
    fontSize: FontStyle.sizeVeryBig,
    border: `3px solid ${Colors.White.primary}`,
    boxShadow: `0px 0px 0 2px ${Colors.Magenta[600]}`,
  },
  editButton: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: -SpacingStyle[8],
    right: -SpacingStyle[12],
    width: 26,
    height: 26,
    color: Colors.Magenta.primary,
    backgroundColor: Colors.Magenta[100],
    border: `4px solid ${Colors.White.primary}`,
    borderRadius: 100,
    zIndex: 100,
  },
  addStory: {
    backgroundColor: Colors.Magenta[50],
    color: Colors.Magenta.primary,
    border: `1px dashed ${Colors.Magenta.primary}`,
    boxShadow: 'none',
  },
  storyPoster: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 100,
  },
};

export default StoriesCard;
