import React, { useEffect, useState } from 'react';
import { Button, useDataProvider, useRefresh } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  ActionType,
  ActionCTASocialCommentType,
  SocialPlatformComment,
} from 'types/action.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import Callout from '@components/callout';
import { validateUrl } from '@components/inputs/url_input';
import { FontStyle, SpacingStyle } from '@styles/variables';
import InputStyle from '@styles/input';

type ActionCtaSocialCommentsActionCardDialogProps = {
  action: ActionType;
  actionCtaSocialComment?: ActionCTASocialCommentType;
};
const ActionCtaSocialCommentsActionCardDialog = ({
  action,
  actionCtaSocialComment,
}: ActionCtaSocialCommentsActionCardDialogProps) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { canCreate, canUpdate } = useRoleBasedPermissions();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [postUrl, setPostUrl] = useState('');
  const [socialPlatform, setSocialPlatform] = useState<SocialPlatformComment>();
  const [messages, setMessages] = useState<string[]>(['']);
  const [gptPrompt, setGptPrompt] = useState<string>('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!action) {
      return;
    }
    if (actionCtaSocialComment) {
      setPostUrl(actionCtaSocialComment.postUrl || '');
      setSocialPlatform(actionCtaSocialComment.socialPlatform);
      setGptPrompt(actionCtaSocialComment.gptPrompt || '');
      const newMessages = [];
      for (let i = 1; i <= 20; i++) {
        const message = (actionCtaSocialComment as any)[`message${i}`] as string;
        if (message !== null && message !== undefined) {
          newMessages.push(message);
        }
      }
      if (newMessages.length > 0) {
        setMessages(newMessages);
      }
    }
  }, [action, actionCtaSocialComment]);

  useEffect(() => {
    if (!postUrl) {
      setError('URL is required');
    } else if (postUrl.length > 250) {
      setError('URL is too long');
    } else if (!postUrl?.startsWith('https://')) {
      setError('Post URL must start with "https://"');
    } else {
      setError(validateUrl(postUrl) || '');
    }
  }, [postUrl]);

  /**
   * Check if the action already has a CTA for the given social platform
   * @param platform
   * @returns boolean
   */
  const actionAlreadyHasSocial = (platform: SocialPlatformComment) => {
    return action.actionCtaSocialComments?.some(cta => cta.socialPlatform === platform);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsSubmitting(false);
  };

  const handleClose = (withConfirmation = false) => {
    if (isSubmitting) {
      window.alert("You can't close this modal while the action is being saved.");
      return;
    }
    const confirmSentence =
      'Are you sure you want to close this modal?\nYour changes will be lost.';
    if (withConfirmation && !window.confirm(confirmSentence)) {
      return;
    }
    setOpen(false);
  };

  const saveCTA = async () => {
    if (!action || !!isSubmitting) {
      return;
    }
    if (!postUrl || !messages.length || messages[0] === '' || !socialPlatform) {
      return;
    }
    setIsSubmitting(true);
    try {
      if (!actionCtaSocialComment) {
        await dataProvider.create('action_cta_social_comments', {
          data: {
            actionId: action.id,
            postUrl,
            socialPlatform,
            message1: messages[0] || null,
            message2: messages[1] || null,
            message3: messages[2] || null,
            message4: messages[3] || null,
            message5: messages[4] || null,
            message6: messages[5] || null,
            message7: messages[6] || null,
            message8: messages[7] || null,
            message9: messages[8] || null,
            message10: messages[9] || null,
            message11: messages[10] || null,
            message12: messages[11] || null,
            message13: messages[12] || null,
            message14: messages[13] || null,
            message15: messages[14] || null,
            message16: messages[15] || null,
            message17: messages[16] || null,
            message18: messages[17] || null,
            message19: messages[18] || null,
            message20: messages[19] || null,
          },
        });
      } else {
        await dataProvider.update('action_cta_social_comments', {
          id: actionCtaSocialComment.id,
          data: {
            postUrl,
            socialPlatform,
            gptPrompt,
            message1: messages[0] || null,
            message2: messages[1] || null,
            message3: messages[2] || null,
            message4: messages[3] || null,
            message5: messages[4] || null,
            message6: messages[5] || null,
            message7: messages[6] || null,
            message8: messages[7] || null,
            message9: messages[8] || null,
            message10: messages[9] || null,
            message11: messages[10] || null,
            message12: messages[11] || null,
            message13: messages[12] || null,
            message14: messages[13] || null,
            message15: messages[14] || null,
            message16: messages[15] || null,
            message17: messages[16] || null,
            message18: messages[17] || null,
            message19: messages[18] || null,
            message20: messages[19] || null,
          },
        } as any);
      }
      refresh();
      setOpen(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (
    !action ||
    !canCreate('action_cta_social_comments') ||
    !canUpdate('action_cta_social_comments')
  ) {
    return null;
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        label={actionCtaSocialComment ? 'Edit' : 'Add'}
        endIcon={actionCtaSocialComment ? <EditIcon /> : <AddIcon />}
        size="small"
        style={{
          textTransform: 'none',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        }}
      />
      <Dialog onClose={() => handleClose(true)} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Write comments to a post</DialogTitle>
        <DialogContent>
          {/* Social Platform */}
          <label style={{ fontSize: FontStyle.sizeVeryVerySmall }}>
            Social Platform:
            <select
              style={InputStyle.input}
              value={socialPlatform}
              onChange={e => setSocialPlatform(e.target.value as SocialPlatformComment)}
            >
              <option value="">Select a social platform</option>
              <option
                value={SocialPlatformComment.INSTAGRAM}
                disabled={actionAlreadyHasSocial(SocialPlatformComment.INSTAGRAM)}
              >
                Instagram
              </option>
              <option
                value={SocialPlatformComment.LINKEDIN}
                disabled={actionAlreadyHasSocial(SocialPlatformComment.LINKEDIN)}
              >
                LinkedIn
              </option>
              <option
                value={SocialPlatformComment.TWITTER}
                disabled={actionAlreadyHasSocial(SocialPlatformComment.TWITTER)}
              >
                X (Twitter)
              </option>
            </select>
          </label>
          <br />
          {/* Post URL */}
          <label style={{ fontSize: FontStyle.sizeVeryVerySmall }}>
            Post URL:
            <input
              type="url"
              style={InputStyle.input}
              placeholder={`ex: https://${socialPlatform || 'instagram'}.com/DTb7kmpb`}
              value={postUrl}
              onChange={e => {
                setPostUrl(e.target.value);
              }}
            />
          </label>
          {/* Messages */}
          <Callout backgroundColor={'yellow'} emoji="👇">
            You can write up to 20 different messages, mentionning different topics.
            <br />
            GPT will take one of them and generate a unique variation of it before posting
            it.
            {socialPlatform === SocialPlatformComment.TWITTER && (
              <>
                <br />
                <br />
                <b>⚠️ Warning:</b> Twitter has a 280 characters limit. Messages length
                will be truncated to 220 characters.
              </>
            )}
          </Callout>
          {messages.map((message, index) => (
            <label style={{ fontSize: FontStyle.sizeVeryVerySmall }} key={index}>
              Message {index + 1}:
              <div style={{ display: 'flex' }}>
                <textarea
                  style={InputStyle.input}
                  placeholder={`- max length: ${socialPlatform === SocialPlatformComment.TWITTER ? 220 : 400}\n- ex: I love this place!`}
                  value={message}
                  onChange={e => {
                    const newMessages = [...messages];
                    newMessages[index] = e.target.value;
                    setMessages(newMessages);
                  }}
                  rows={4}
                  maxLength={socialPlatform === SocialPlatformComment.TWITTER ? 220 : 400}
                />
                {index > 0 && (
                  <Button
                    startIcon={<DeleteOutlineIcon />}
                    onClick={() => {
                      const newMessages = [...messages];
                      newMessages.splice(index, 1);
                      setMessages(newMessages);
                    }}
                    disabled={messages.length <= 1 || index === 0}
                    style={{
                      marginLeft: 10,
                    }}
                  />
                )}
              </div>
            </label>
          ))}
          {messages.length < 20 && (
            <Button
              label="Add a message"
              startIcon={<AddIcon />}
              onClick={() => {
                const newMessages = [...messages];
                newMessages.push('');
                setMessages(newMessages);
              }}
              disabled={messages[messages.length - 1] === ''}
              style={{
                marginTop: SpacingStyle.small,
                marginBottom: SpacingStyle.normal,
              }}
            />
          )}
          {error && <div style={{ color: 'red' }}>{error}</div>}

          {/* GPT Prompt */}
          {actionCtaSocialComment && (
            <label
              style={{
                display: 'block',
                fontSize: FontStyle.sizeVeryVerySmall,
              }}
            >
              🤖 GPT Prompt:
              <textarea
                style={InputStyle.input}
                placeholder="This prompt will be passed to GPT when users will interact with this CTA."
                value={gptPrompt}
                onChange={e => setGptPrompt(e.target.value)}
                rows={5}
              />
            </label>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveCTA}
            label={isSubmitting ? 'Saving...' : 'Save'}
            disabled={
              isSubmitting ||
              !postUrl?.startsWith('https://') ||
              !socialPlatform ||
              error.length > 0
            }
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActionCtaSocialCommentsActionCardDialog;
