import * as React from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

const ShowActions = <ResourceTitleActionBar mode="show" />;

export const MissionCommentShow = () => (
  <Show actions={ShowActions}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="missionId" reference="missions" link="show" label="Mission">
        <TextField source="titleEN" label="Mission" />
      </ReferenceField>

      <ReferenceField source="authorId" reference="users" link="show">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="message" />
      <DateField
        source="createdAt"
        showTime
        label={`Posted at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
    </SimpleShowLayout>
  </Show>
);
