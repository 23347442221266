import React from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import PreviewIcon from '@mui/icons-material/RemoveRedEye';
import { ActionType } from 'types/action';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from 'src/styles/variables';

const PreviewCard = () => {
  const action: ActionType = useRecordContext();
  const [lang, setLang] = React.useState<'EN' | 'FR'>('EN');

  if (!action) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader title="Preview" avatar={<PreviewIcon />} />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={styles.switchContainer}>
          <div
            style={{
              ...styles.switchOption,
              ...(lang === 'EN' ? styles.switchOptionActive : {}),
            }}
            onClick={() => setLang('EN')}
          >
            English
          </div>
          <div
            style={{
              ...styles.switchOption,
              ...(lang === 'FR' ? styles.switchOptionActive : {}),
            }}
            onClick={() => setLang('FR')}
          >
            French
          </div>
        </div>

        <div style={styles.actionBlock}>
          <img style={styles.owner} src={action.owners?.[0]?.avatarNoBackgroundUrl} />
          <img style={styles.mission} src={action.mission?.thumbnailUrl} />
          <div style={styles.container}>
            <div style={styles.title}>{action[`title${lang}`] || 'No title'}</div>

            <div style={styles.description}>
              {action[`description${lang}`] || 'No description'}
            </div>

            <div style={styles.footer}>
              <div style={styles.members}>
                <div style={styles.member}>M</div>
                <div style={styles.member}>C</div>
                <div style={styles.member}>J</div>
                <div style={styles.memberCount}>
                  {action?.usersCount || 0}
                  <small>/{action?.usersObjective || 50}</small>
                </div>
              </div>
              <div style={styles.footerCTA}>do it</div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const styles: any = {
  switchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    marginBottom: SpacingStyle.big,
    padding: SpacingStyle[4],
    gap: SpacingStyle.small,
    border: `1px solid ${Colors.Grey[200]}`,
    borderRadius: BorderStyle.Radius.big,
  },
  switchOption: {
    borderRadius: BorderStyle.Radius.big,
    padding: `${SpacingStyle[4]}px ${SpacingStyle[12]}px`,
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    border: `2px solid ${Colors.White.primary}`,
  },
  switchOptionActive: {
    color: Colors.White.primary,
    backgroundColor: Colors.OffBlack.primary,
    background: `linear-gradient(to bottom, ${Colors.OffBlack.primary}, ${Colors.Grey[800]})`,
    border: `2px solid ${Colors.OffBlack.primary}`,
  },
  previews: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: SpacingStyle.big,
  },
  actionBlock: {
    position: 'relative',
    marginTop: 50,
  },
  owner: {
    position: 'absolute',
    top: -60,
    left: 30,
    height: 70,
    filter: 'grayscale(100%)',
    transform: 'rotate(-5deg)',
    zIndex: 5,
  },
  mission: {
    position: 'absolute',
    top: -50,
    right: 10,
    height: 100,
    width: '70%',
    objectFit: 'cover',
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.Black.transparent.max,
    transform: 'rotate(7deg)',
    zIndex: 0,
  },
  container: {
    position: 'relative',
    width: 350,
    padding: SpacingStyle.normal,
    backgroundColor: Colors.White.primary,
    borderRadius: BorderStyle.Radius.normal,
    border: `1px solid ${Colors.Grey[100]}`,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    zIndex: 10,
  },
  title: {
    marginBottom: SpacingStyle.small,
    fontSize: FontStyle.sizeMedium,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
    lineHeight: 1.3,
  },
  description: {
    marginBottom: SpacingStyle.normal,
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 500,
    color: Colors.Grey[800],
    lineHeight: 1.3,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  members: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 6,
  },
  member: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    marginLeft: -8,
    borderRadius: BorderStyle.Radius.big,
    border: `2px solid ${Colors.White.primary}`,
    backgroundColor: Colors.Magenta[600],
    color: Colors.OffBlack.primary,
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
  },
  memberCount: {
    marginLeft: SpacingStyle[4],
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
  },
  footerCTA: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${SpacingStyle[4]}px ${SpacingStyle.normal}px`,
    borderRadius: BorderStyle.Radius.big,
    border: `2px solid ${Colors.OffBlack.primary}`,
    background: `linear-gradient(to bottom, ${Colors.OffBlack.primary}, ${Colors.Grey[800]})`,
    color: Colors.White.primary,
    fontWeight: 600,
    fontSize: FontStyle.sizeSmall,
    cursor: 'default',
  },
};

export default PreviewCard;
