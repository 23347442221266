import React, { useCallback } from 'react';
import {
  Button,
  useDataProvider,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';

export const CampaignCauseList = () => {
  const { id, causes: causes } = useRecordContext();

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { canCreate, canDelete } = useRoleBasedPermissions();

  const handleItemClick = useCallback(
    (index: number) => {
      redirect('show', 'causes', causes[index].id);
    },
    [causes, redirect]
  );

  const handleRemoveManager = useCallback(
    async (index: number) => {
      await dataProvider.deleteJunctionRecord(
        'campaigns_causes',
        'campaigns',
        id,
        'causes',
        causes[index].id
      );

      refresh();
    },
    [causes, dataProvider, id, refresh]
  );

  return (
    <div>
      {canCreate('campaigns_causes') && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginBottom: 20,
            columnGap: 10,
          }}
        >
          <Button
            href={`#/campaigns_causes/create?campaignId=${id}`}
            label="Add Cause"
            startIcon={<AddIcon />}
          />
        </div>
      )}
      <table width={'100%'} style={{ borderSpacing: 0 }}>
        <tbody>
          <tr style={{ textAlign: 'left', backgroundColor: '#d5d5d5' }}>
            <th>cause</th>
            <th></th>
          </tr>
          {causes &&
            causes.map((campaignCause: any, i: number) => (
              <tr
                key={i}
                style={{
                  backgroundColor: i % 2 === 1 ? '#f4f4f6' : undefined,
                  cursor: 'pointer',
                }}
              >
                <td style={{ fontSize: '.8em' }} onClick={() => handleItemClick(i)}>
                  {campaignCause.emoji} {campaignCause.name}
                </td>
                <td
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginBottom: 20,
                    columnGap: 10,
                  }}
                >
                  {canDelete('campaigns_causes') && (
                    <Button
                      onClick={() => handleRemoveManager(i)}
                      label="Remove"
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
