import React, { useMemo, useState } from 'react';
import { useDataProvider, useRecordContext, useRefresh } from 'react-admin';
import { Card } from '@mui/material';
import ImageIcon from '@mui/icons-material/CropOriginal';
import { MissionType } from 'types/mission';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import LoadingAnimation from '@components/svgs/loading_animation';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const FILE_SIZE_LIMIT = 5; // 5MB

const ThumbnailCard = () => {
  const mission: MissionType = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [isHovered, setIsHovered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { canUpdate } = useRoleBasedPermissions();

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files?.[0]) {
      return;
    }
    const fileSize = event.target.files[0].size;
    const maxSize = FILE_SIZE_LIMIT * 1024 * 1024; // 5MB in bytes
    if (fileSize > maxSize) {
      alert(`File size exceeds ${FILE_SIZE_LIMIT}MB limit`);
      return;
    }

    try {
      setIsSubmitting(true);
      await dataProvider.update('missions', {
        id: mission.id,
        data: {
          picture: {
            rawFile: event.target.files[0],
          },
        },
      } as any);
      await refresh();
    } catch (error: any) {
    } finally {
      setIsSubmitting(false);
    }
  };

  const outcomeLabel = useMemo(() => {
    if (!mission.outcome) {
      return;
    }
    if (mission.outcome === 'SUCCESS') {
      return '🎉 Mission won';
    }
    if (mission.outcome === 'FAILURE') {
      return '😭 Mission lost';
    }
    if (mission.outcome === 'ON_HOLD') {
      return '⏸️ Mission on hold';
    }
    if (mission.outcome === 'CLOSED') {
      return '🔒 Mission closed';
    }
    return `${mission.outcome}`;
  }, [mission.outcome]);

  return (
    <Card
      style={{
        ...styles.container,
        ...(mission.thumbnailUrl
          ? { backgroundImage: `url(${mission.thumbnailUrl})` }
          : null),
      }}
      onMouseEnter={() => !mission.outcome && setIsHovered(true)}
      onMouseLeave={() => !mission.outcome && setIsHovered(false)}
    >
      {canUpdate('missions') && !mission.outcome && (
        <input
          type="file"
          accept=".jpg,.png"
          onChange={handleFileUpload}
          disabled={isSubmitting}
          style={{
            ...styles.fullContainer,
            ...styles.fullContainerHovered,
            opacity: 0,
            zIndex: 1,
            cursor: 'pointer',
            ...(isSubmitting && { cursor: 'not-allowed' }),
          }}
        />
      )}
      {isSubmitting && (
        <div
          style={{
            ...styles.fullContainer,
            ...styles.fullContainerHovered,
          }}
        >
          <LoadingAnimation />
        </div>
      )}
      {!!outcomeLabel && (
        <div
          style={{
            ...styles.fullContainer,
            ...styles.fullContainerHovered,
          }}
        >
          <div style={styles.outcomeSticker}>
            <strong style={{ fontWeight: 800, fontSize: FontStyle.sizeHuge }}>
              {outcomeLabel}
            </strong>
          </div>
        </div>
      )}
      {canUpdate('missions') && isHovered && !isSubmitting && (
        <div
          style={{
            ...styles.fullContainer,
            ...styles.fullContainerHovered,
          }}
        >
          Click to upload a thumbnail
          <br />
          {FILE_SIZE_LIMIT}MB max, jpg or png
        </div>
      )}
      {!mission.thumbnailUrl && !isHovered && (
        <div style={styles.fullContainer}>
          Click to upload a thumbnail
          <br />
          {FILE_SIZE_LIMIT}MB max, jpg or png
        </div>
      )}
      {!!mission.thumbnailUrl && (
        <a
          href={mission.thumbnailUrl}
          target="_blank"
          rel="noreferrer"
          style={styles.fullScreenLink}
        >
          <ImageIcon />
          View full image
        </a>
      )}
    </Card>
  );
};

const styles: any = {
  container: {
    position: 'relative',
    backgroundColor: Colors.Grey[200],
    background: `linear-gradient(-185deg, ${Colors.Magenta[600]}, ${Colors.Magenta[800]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: 300,
    margin: SpacingStyle.small,
    boxShadow: `0px 2px 10px 0px ${Colors.Black.transparent.max}`,
    borderRadius: BorderStyle.Radius.normal,
    overflow: 'hidden',
  },
  fullContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    textAlign: 'center',
    color: Colors.White.primary,
  },
  fullContainerHovered: {
    backdropFilter: 'blur(5px)',
    backgroundColor: Colors.Black.transparent.medium,
  },
  outcomeSticker: {
    cursor: 'default',
    padding: `${SpacingStyle.small}px ${SpacingStyle[40]}px`,
    backgroundColor: Colors.Magenta.primary,
    borderRadius: BorderStyle.Radius.normal,
    fontSize: FontStyle.sizeBig,
    lineHeight: 1.2,
    transform: 'rotate(-4deg)',
  },
  fullScreenLink: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: SpacingStyle[4],
    top: 0,
    right: 0,
    padding: `${SpacingStyle[4]}px ${SpacingStyle.small}px`,
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
    textDecoration: 'none',
    zIndex: 10,
    cursor: 'pointer',
    borderRadius: `0 0 0 ${BorderStyle.Radius.small}px`,
    backgroundColor: Colors.White.transparent.light,
    backdropFilter: 'blur(5px)',
  },
};

export default ThumbnailCard;
