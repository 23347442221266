import * as React from 'react';
import {
  DateTimeInput,
  DeleteButton,
  Edit,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `missions/${data.id}/show`;

const MissionEditActions = () => <ResourceTitleActionBar mode="edit" />;

/**
 * @returns Edit toolbar with delete button for super admins
 */
const MissionEditToolbar = ({ ...props }) => {
  const { canUpdate, canDelete } = useRoleBasedPermissions();
  return (
    <Toolbar {...props}>
      {canUpdate('missions') && <SaveButton variant="contained" />}
      {canDelete('missions') && <DeleteButton variant="outlined" />}
    </Toolbar>
  );
};

export const MissionEdit = ({ ...props }) => (
  <Edit
    redirect={redirectAfterEdit}
    mutationMode="pessimistic"
    actions={<MissionEditActions />}
    {...props}
  >
    <SimpleForm toolbar={<MissionEditToolbar />}>
      <CustomReferenceInput source="campaignId" reference="campaigns" disabled />
      <CustomReferenceInput
        source="ownerId"
        reference="users"
        validate={[required()]}
        sort={{ field: 'firstname', order: 'ASC' }}
      />
      <TextInput
        label="English title"
        source="titleEN"
        fullWidth
        validate={[required()]}
      />
      <TextInput
        label="English description"
        source="descriptionEN"
        fullWidth
        multiline
        validate={[required()]}
      />
      <TextInput
        label="French title"
        source="titleFR"
        fullWidth
        validate={[required()]}
      />
      <TextInput
        label="French description"
        source="descriptionFR"
        fullWidth
        multiline
        validate={[required()]}
      />
      <ImageField source="thumbnailUrl" />
      <ImageInput source="picture" accept="image/*" maxSize={5000000}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <DateTimeInput source="publishedAt" />
    </SimpleForm>
  </Edit>
);
