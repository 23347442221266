import React from 'react';
import { FunctionField, SelectInput, required } from 'react-admin';
import { AdminUserRole } from 'types/admin_user_role.d';

export const AdminUserRoleNames = {
  [AdminUserRole.super_admin]: 'super_admin',
  [AdminUserRole.campaign_admin]: 'campaign_admin',
  [AdminUserRole.campaign_manager]: 'campaign_manager',
  [AdminUserRole.product]: 'product',
  [AdminUserRole.read_only]: 'read_only',
};

export const AdminUserRoles = Object.values(AdminUserRoleNames);

type RoleFieldProps = {
  source?: string;
  defaultValue?: AdminUserRole;
};

export const AdminUserRoleField = ({ source = 'role' }: RoleFieldProps) => (
  <FunctionField
    label="Role"
    source={source}
    render={(resource: any) => {
      for (let i = 0; i < AdminUserRoles.length; i++) {
        if (resource.role === i) {
          return AdminUserRoles[i];
        }
      }
      return 'none';
    }}
  />
);

export const AdminUserRoleInput = ({
  source = 'role',
  defaultValue = AdminUserRole.read_only,
}: RoleFieldProps) => {
  const choices = [];

  for (let i = 0; i < AdminUserRoles.length; i++) {
    choices.push({ id: i, name: AdminUserRoles[i] });
  }
  return (
    <SelectInput
      source={source}
      label="Admin role"
      choices={choices}
      validate={[required()]}
      defaultValue={defaultValue}
    />
  );
};
