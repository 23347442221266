import React, { useCallback } from 'react';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { AdminUserRole } from 'types/admin_user_role.d';
import { CampaignType } from 'types/campaign';

import CampaignBlock from '@models/campaigns/components/campaign_block';

export const AdminUserCampaignsList = () => {
  const record = useRecordContext();
  const redirect = useRedirect();

  const handleItemClick = useCallback(
    (index: any, _resource: string, record: any) => {
      redirect(`/campaigns/${record.id}/show/managers`);
      return false;
    },
    [redirect]
  );

  if (record.role === AdminUserRole.campaign_manager) {
    return (
      <ArrayField source="campaigns">
        <Datagrid bulkActionButtons={false} rowClick={handleItemClick}>
          <FunctionField
            label="Campaign"
            render={(record: CampaignType) => <CampaignBlock campaign={record} />}
          />
        </Datagrid>
      </ArrayField>
    );
  }

  return <>All</>;
};
