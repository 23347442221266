import { AuthProvider } from 'react-admin';

import { RoleBasedPermissionsType } from '@hooks/useRoleBasedPermissions';

import Config from './config';
import { httpClient } from './dataProvider';

let roleBasedPermissions: RoleBasedPermissionsType = {};

type LoginProps = {
  username: string;
  password: string;
};
const authProvider: AuthProvider = {
  login: ({ username, password }: LoginProps) => {
    const request = new Request(`${Config.API_URL}/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(res => {
        localStorage.setItem('authToken', res.data.authToken);
        localStorage.setItem('role', res.data.role);
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },

  checkError: error => {
    const status = error?.status || 500;
    if (status === 401) {
      localStorage.removeItem('authToken');
      return Promise.reject();
    }
    if (status === 403) {
      return Promise.reject({
        redirectTo: '',
        logoutUser: false,
        message: error.body?.error?.message,
      });
    }
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem('authToken') && localStorage.getItem('role')
      ? Promise.resolve()
      : Promise.reject(),

  logout: async () => {
    const token = localStorage.getItem('authToken');
    const request = new Request(`${Config.API_URL}/logout`, {
      method: 'POST',
      headers: new Headers({ Authorization: `Bearer ${token}` }),
    });
    await fetch(request);
    localStorage.removeItem('authToken');
    localStorage.removeItem('role');
    return Promise.resolve();
  },

  // get the user's profile
  getIdentity: async () => {
    const {
      json: { data },
    } = await httpClient(`${Config.API_URL}/admin_users/me`);
    return data;
  },

  // get the user permissions
  getPermissions: async (): Promise<RoleBasedPermissionsType> => {
    if (Object.keys(roleBasedPermissions).length) {
      return Promise.resolve(roleBasedPermissions);
    }

    try {
      const {
        json: { data },
      } = await httpClient(`${Config.API_URL}/rbp`);
      roleBasedPermissions = data;
      return Promise.resolve(data);
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  },
};

export default authProvider;
