import * as React from 'react';
import { Datagrid, Filter, List, SearchInput, TextField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = (
  <ResourceTitleActionBar
    mode="list"
    filters={
      <Filter>
        <SearchInput source="name" alwaysOn />
      </Filter>
    }
  />
);

export const CauseList = () => (
  <List
    exporter={false}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="emoji" />
      <TextField source="name" />
      <TextField source="descriptionEN" label="Description EN" />
    </Datagrid>
  </List>
);
