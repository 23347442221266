import * as React from 'react';
import {
  DeleteButton,
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRecordContext,
} from 'react-admin';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import Callout from '@components/callout';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterEdit = (_: any, __: any, data: any) => `actions/${data.id}/show`;

const redirectAfterDelete = (_: any, __: any, data: any) =>
  `campaigns/${data.campaignId}/show`;

const ActionEditActions = () => <ResourceTitleActionBar mode="edit" />;

/**
 * @param {*} props
 */
const ActionEditToolbar = ({ ...props }) => {
  const record = useRecordContext(props);
  const { canUpdate, canDelete } = useRoleBasedPermissions();

  return (
    <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}
    >
      {canUpdate('actions') && <SaveButton />}
      {canDelete('actions') && (
        <DeleteButton
          redirect={(basePath: any) => redirectAfterDelete(basePath, record.id, record)}
        />
      )}
    </Toolbar>
  );
};

export const ActionEdit = () => {
  return (
    <Edit
      redirect={redirectAfterEdit}
      mutationMode="pessimistic"
      actions={<ActionEditActions />}
    >
      <SimpleForm toolbar={<ActionEditToolbar />}>
        <CustomReferenceInput source="missionId" reference="missions" queryKey="name" />
        <Callout emoji="⚠️" backgroundColor="yellow">
          Are you sure you want to delete this action?
        </Callout>
      </SimpleForm>
    </Edit>
  );
};
