import * as React from 'react';
import {
  DeleteButton,
  Edit,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import Grammarly from '@components/grammarly';
import UrlInput from '@components/inputs/url_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Callout from '@components/callout';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `campaigns/${data.id}/show`;

const CampaignEditActions = <ResourceTitleActionBar mode="edit" />;

/**
 * @returns Edit toolbar with delete button for super admins
 */
const CampaignEditToolbar = ({ ...props }) => {
  const { canDelete } = useRoleBasedPermissions();
  return (
    <Toolbar {...props}>
      <SaveButton variant="contained" />
      {canDelete('campaigns') && <DeleteButton variant="outlined" />}
    </Toolbar>
  );
};

export const CampaignEdit = ({ ...props }) => (
  <Edit
    redirect={redirectAfterEdit}
    mutationMode="pessimistic"
    actions={CampaignEditActions}
    {...props}
  >
    <SimpleForm toolbar={<CampaignEditToolbar />}>
      <TextInput source="name" fullWidth validate={[required()]} />
      <Grammarly>
        <TextInput
          label="English description"
          source="descriptionEN"
          fullWidth
          multiline
          validate={[required()]}
        />
      </Grammarly>
      <TextInput
        label="French description"
        source="descriptionFR"
        fullWidth
        multiline
        validate={[required()]}
      />
      <ImageField source="imageUrl" fullWidth />
      <ImageInput source="picture" accept="image/*" maxSize={5000000}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <br />
      <Callout backgroundColor="grey" emoji="👇">
        When a date is set, the campaign will be visible to users starting from that date.
        <br />
        When no date, the campaign will be hidden.
      </Callout>
      <br />
      <UrlInput source="websiteUrl" label="Website URL" fullWidth />
      <UrlInput source="instagramHandle" label="Instagram URL" fullWidth />
      <UrlInput source="tiktokHandle" label="TikTok URL" fullWidth />
      <UrlInput source="facebookHandle" label="Facebook URL" fullWidth />
      <UrlInput source="twitterHandle" label="Twitter (X) URL" fullWidth />
      <UrlInput source="linkedinHandle" label="LinkedIn URL" fullWidth />
      <UrlInput source="snapchatHandle" label="Snapchat URL" fullWidth />
    </SimpleForm>
  </Edit>
);
