import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { TbLocation } from 'react-icons/tb';
import { flag } from 'country-emoji';
import { Card, CardContent, CardHeader } from '@mui/material';
import { MissionType } from 'types/mission.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { countries, usStates } from '@modules/geolocation';
import TodoChecklist from '@components/todo_checklist';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import { FontStyle } from '@styles/variables';

const LocationCard = () => {
  const mission: MissionType = useRecordContext();
  const { canUpdate } = useRoleBasedPermissions();

  /**
   * List of countries
   */
  const options = useMemo(() => {
    const countriesList = countries.map(country => ({
      id: country.alpha2,
      name: `${country.name} ${flag(country.alpha2)}`,
    }));
    return [{ id: 'none', name: 'Select a country' }, ...countriesList];
  }, []);

  /**
   * List of states for the selected country
   */
  const stateOptions = useMemo(() => {
    const statesList = usStates.map(state => ({
      id: state.state,
      name: `${state.state}`,
    }));
    return [{ id: 'none', name: 'Select a state' }, ...statesList];
  }, []);

  /**
   * Format the country to be displayed in the select input
   * @param location
   */
  const countryFormatter = (location?: string | null) => {
    if (!location) {
      return null;
    }
    const country = countries.find(country => country.alpha2 === location);

    return {
      ...country,
      state: null,
    };
  };

  /**
   * Format the state to be displayed in the select input
   * @param location
   */
  const stateFormatter = (location?: string | null) => {
    const unitedStates = countries.find(country => country.alpha2 === 'US');
    if (!location) {
      return unitedStates;
    }
    const state = usStates.find(state => state.state === location);

    return {
      ...unitedStates,
      state: state?.state,
      latitude: state?.latitude || unitedStates?.latitude,
      longitude: state?.longitude || unitedStates?.longitude,
    };
  };

  const selectedCountry = useMemo(
    () => countries.find(country => country.alpha2 === mission?.location?.country),
    [mission]
  );

  const selectedState = useMemo(
    () => usStates.find(state => state.state === mission?.location?.state),
    [mission]
  );

  /**
   * Label to display in the card
   */
  const label = useMemo(() => {
    let label = '';

    if (mission?.location?.state) {
      label += `${selectedState?.state}, `;
    }

    if (selectedCountry) {
      label += `${selectedCountry.name} ${flag(selectedCountry.alpha2)}`;
    }

    return label;
  }, [selectedState, selectedCountry, mission]);

  return (
    <Card>
      <CardHeader title="Location" avatar={<TbLocation />} />
      <CardContent>
        <TodoChecklist
          checked={!!mission?.location}
          label="Set a location"
          cta={
            canUpdate('missions') &&
            !mission.outcome && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <UpdateFieldDialog
                  resource="missions"
                  record={mission}
                  type="select"
                  field="location"
                  buttonLabel={!mission?.location ? 'Set a location' : 'Edit the country'}
                  buttonVariant="outlined"
                  selectOptions={options}
                  defaultValue={mission?.location?.country || selectedCountry?.alpha2}
                  showSaveButton
                  formatter={countryFormatter}
                />

                {mission?.location?.country === 'US' && (
                  <UpdateFieldDialog
                    resource="missions"
                    record={mission}
                    type="select"
                    field="location"
                    buttonLabel={
                      mission?.location?.state ? 'Edit the state' : 'Set a state'
                    }
                    buttonVariant="outlined"
                    selectOptions={stateOptions}
                    defaultValue={selectedState?.state}
                    showSaveButton
                    formatter={stateFormatter}
                  />
                )}
              </div>
            )
          }
        >
          {selectedCountry && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                fontSize: FontStyle.sizeSmall,
              }}
            >
              {label}
            </div>
          )}
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default LocationCard;
