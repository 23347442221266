/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Layout,
  Menu,
  useGetIdentity,
  useLogout,
  useSidebarState,
} from 'react-admin';
import { Link, To, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import BullWorkerIcon from '@mui/icons-material/DynamicForm';
import LockIcon from '@mui/icons-material/Lock';
import QRCode from '@mui/icons-material/QrCode2';
import Calendar from '@mui/icons-material/InsertInvitation';
import { TbMessage, TbPolaroidFilled, TbTagStarred } from 'react-icons/tb';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import Symbol from '@components/svgs/logos/symbol';
import {
  ActionIcon,
  AdminUserIcon,
  BadgeIcon,
  CampaignIcon,
  CauseIcon,
  CRMOrganizationIcon,
  CRMPersonIcon,
  CRMTagIcon,
  DonationIcon,
  FeatureFlagIcon,
  InAppNotificationsIcon,
  MissionIcon,
  PillIcon,
  TopicIcon,
  UserIcon,
} from '@components/icons';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import { httpClient } from './dataProvider';
import Config from './config';

const MenuItemIcon = ({ icon }: any) =>
  React.cloneElement(icon, {
    size: FontStyle.sizeBig,
  });

type CustomMenuSectionProps = {
  title: string;
  compact: boolean;
  children?: React.ReactNode;
  shouldRender?: boolean;
};
const CustomMenuSection = ({
  title,
  compact,
  children,
  shouldRender = true,
}: CustomMenuSectionProps) => {
  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <div
        style={{
          marginTop: SpacingStyle.normal,
          marginBottom: SpacingStyle[4],
          marginLeft: SpacingStyle.big,
          fontSize: FontStyle.sizeVerySmall,
          fontWeight: 600,
          color: Colors.Grey[400],
          textTransform: 'uppercase',
          cursor: 'default',
          height: compact ? '10px' : 'auto',
        }}
      >
        {!compact && title}
      </div>
      {children}
    </>
  );
};

type CustomMenuItemProps = {
  to?: To;
  model?: string;
  onClick?: () => void;
  icon: React.ReactNode;
  label: string;
  compact: boolean;
  hasPermission?: boolean;
};
const CustomMenuItem = ({
  to,
  model,
  icon,
  label,
  compact,
  hasPermission,
  onClick,
  ...props
}: CustomMenuItemProps) => {
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const location = useLocation();
  const { isTableValid, canRead } = useRoleBasedPermissions();
  const [hasPermissionToRead, setHasPermissionToRead] = useState(false);

  useEffect(() => {
    const mainPath = location.pathname.split('/')[1];
    setActive(`/${mainPath}` === to || mainPath === model);
  }, [location.pathname, to, model]);

  useEffect(() => {
    if (model) {
      setHasPermissionToRead(isTableValid(model) && canRead(model));
    }
    if (hasPermission) {
      setHasPermissionToRead(hasPermission);
    }
  }, [isTableValid, canRead, model, hasPermission]);

  const width = useMemo(() => {
    if (compact) {
      return '43px';
    }
    return 'auto';
  }, [compact]);

  const backgroundColor = useMemo(() => {
    if (active) {
      return Colors.Magenta[100];
    }
    if (hovered) {
      return Colors.Magenta[50];
    }
    return 'transparent';
  }, [active, hovered]);

  const marginVertical = useMemo(() => {
    if (compact) {
      return SpacingStyle[4];
    }
    return SpacingStyle[16];
  }, [compact]);

  const color = useMemo(() => {
    if (active) {
      return Colors.Magenta.primary;
    }
    return Colors.Grey[800];
  }, [active]);

  const Component = to || model ? Link : ('div' as any);

  if (!hasPermissionToRead) {
    return <></>;
  }

  return (
    <Component
      to={to || `/${model}`}
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        width,
        margin: `${SpacingStyle[2]}px ${marginVertical}px`,
        padding: `${SpacingStyle[8]}px ${SpacingStyle[12]}px`,
        color,
        fontSize: FontStyle.sizeSmall,
        fontWeight: '500',
        borderRadius: BorderStyle.Radius.normal,
        gap: SpacingStyle[8],
        backgroundColor,
        cursor: 'pointer',
        textDecoration: 'none',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    >
      {icon && <MenuItemIcon icon={icon} />}
      {!compact && label}
    </Component>
  );
};

const AdminUserFooter = ({ compact }: { compact: boolean }) => {
  const { data, isLoading, error } = useGetIdentity();
  const logout = useLogout();

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <div
      style={{
        padding: `${SpacingStyle[12]}px ${SpacingStyle[16]}px`,
        fontSize: FontStyle.sizeVerySmall,
        fontWeight: '500',
        color: Colors.Grey[400],
        textTransform: 'uppercase',
      }}
    >
      {!compact && <div>{data?.username}</div>}
      <div
        onClick={handleLogout}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: SpacingStyle[8],
          cursor: 'pointer',
          marginTop: SpacingStyle[4],
        }}
      >
        <LockIcon
          style={{
            fontSize: compact ? FontStyle.sizeMedium : FontStyle.sizeVerySmall,
          }}
        />
        {!compact && 'logout'}
      </div>
    </div>
  );
};

const CustomMenu = ({ ...props }) => {
  const [_open, setOpen] = useSidebarState();
  const isMediumScreen = useMediaQuery('(max-width: 1080px)');
  const { isSuperAdmin, isCampaignAdmin, isCampaignManager, isProduct, isReadOnly } =
    useRoleBasedPermissions();

  useEffect(() => {
    setOpen(!isMediumScreen);
  }, [isMediumScreen, setOpen]);

  /**
   * Redirect to BullBoard
   */
  const redirectToBullBoard = async () => {
    const {
      data: { redirectTo },
    } = await httpClient(`${Config.API_URL}/bullboard/jwt`).then(({ json }) => ({
      data: json.data,
    }));
    if (redirectTo) {
      window.open(redirectTo, '_blank');
    }
  };

  return (
    <Menu {...props} style={{ justifyContent: 'space-between' }}>
      <div style={{ flex: 1 }}>
        <a
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle.small,
            marginTop: SpacingStyle.normal,
            marginLeft: isMediumScreen ? SpacingStyle[12] : SpacingStyle.big,
            marginBottom: isMediumScreen ? SpacingStyle[12] : 'auto',
          }}
          href="#"
        >
          <Symbol height={30} width={30} fill={Colors.Magenta.primary} />
          {window.location.hostname === 'localhost' && 'localhost'}
        </a>

        {/* Campaigning */}
        <CustomMenuSection title="Campaigning" compact={isMediumScreen}>
          <CustomMenuItem
            model="campaigns"
            label="Campaigns"
            icon={<MenuItemIcon icon={<CampaignIcon />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="missions"
            label="Missions"
            icon={<MenuItemIcon icon={<MissionIcon />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="actions"
            label="Actions"
            icon={<MenuItemIcon icon={<ActionIcon />} />}
            compact={isMediumScreen}
          />
        </CustomMenuSection>

        {/* Contact Directory */}
        <CustomMenuSection title="Contact Directory" compact={isMediumScreen}>
          <CustomMenuItem
            model="crm_organizations"
            label="Organizations"
            icon={<MenuItemIcon icon={<CRMOrganizationIcon />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="crm_persons"
            label="Persons"
            icon={<MenuItemIcon icon={<CRMPersonIcon />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="crm_tags"
            label="Tags"
            icon={<MenuItemIcon icon={<CRMTagIcon />} />}
            compact={isMediumScreen}
          />
        </CustomMenuSection>

        {/* Product */}
        <CustomMenuSection title="Product" compact={isMediumScreen}>
          <CustomMenuItem
            to="/qr_code_generator"
            label="QR Code Generator"
            icon={<MenuItemIcon icon={<QRCode />} />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
          <CustomMenuItem
            model="badges"
            label="Badges"
            icon={<MenuItemIcon icon={<BadgeIcon />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="causes"
            label="Causes"
            icon={<MenuItemIcon icon={<CauseIcon />} />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin}
          />
          <CustomMenuItem
            model="feature_flags"
            label="Feature Flags"
            icon={<MenuItemIcon icon={<FeatureFlagIcon />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            to="/in_app_notifications"
            label="In-app notifs"
            icon={<MenuItemIcon icon={<InAppNotificationsIcon />} />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
          <CustomMenuItem
            model="pepper_pills"
            label="Pepper Pills"
            icon={<MenuItemIcon icon={<PillIcon />} />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
          <CustomMenuItem
            model="topics"
            label="Topics"
            icon={<MenuItemIcon icon={<TopicIcon />} />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin}
          />
          <CustomMenuItem
            onClick={redirectToBullBoard}
            label="Workers"
            icon={<MenuItemIcon icon={<BullWorkerIcon />} />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isProduct}
          />
        </CustomMenuSection>

        {/* Shop */}
        <CustomMenuSection
          title="Shop"
          compact={isMediumScreen}
          shouldRender={isSuperAdmin || isCampaignAdmin || isProduct}
        >
          <CustomMenuItem
            to="/shop"
            label="Calendar"
            icon={<MenuItemIcon icon={<Calendar />} />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
          <CustomMenuItem
            model="donations"
            label="Donations"
            icon={<MenuItemIcon icon={<DonationIcon />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            to="/deals"
            label="Deals"
            icon={<MenuItemIcon icon={<BadgeIcon />} />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
        </CustomMenuSection>

        {/* Profile */}
        <CustomMenuSection
          title="Users Profile"
          compact={isMediumScreen}
          shouldRender={isSuperAdmin || isCampaignAdmin || isProduct}
        >
          <CustomMenuItem
            model="profile_prompts"
            label="Prompts"
            icon={<MenuItemIcon icon={<TbMessage />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="profile_skills"
            label="Skills"
            icon={<MenuItemIcon icon={<TbPolaroidFilled />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="profile_tags"
            label="Tags"
            icon={<MenuItemIcon icon={<TbTagStarred />} />}
            compact={isMediumScreen}
          />
        </CustomMenuSection>

        {/* People */}
        <CustomMenuSection title="People" compact={isMediumScreen}>
          <CustomMenuItem
            model="admin_users"
            label="Admins"
            icon={<MenuItemIcon icon={<AdminUserIcon />} />}
            compact={isMediumScreen}
          />
          <CustomMenuItem
            model="users"
            label="Users"
            icon={<MenuItemIcon icon={<UserIcon />} />}
            compact={isMediumScreen}
            hasPermission={isSuperAdmin || isCampaignAdmin || isProduct}
          />
        </CustomMenuSection>
      </div>
      <AdminUserFooter compact={isMediumScreen} />
    </Menu>
  );
};

const CustomAppBar = () => <AppBar style={{ display: 'none' }} />;

export const CustomLayout = ({ ...props }) => (
  <Layout {...props} menu={CustomMenu} appBar={CustomAppBar} />
);
