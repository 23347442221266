import React, { useCallback, useMemo, useState } from 'react';
import { CampaignType } from 'types/campaign';
import { compressedImageUrl } from 'src/modules/cloudinary';

type CampaignBlockProps = {
  campaign?: CampaignType;
  clickable?: boolean | string;
  style?: any;
  label?: string;
};
const CampaignBlock = ({ campaign, clickable = false, style }: CampaignBlockProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const imageUrl = useMemo(() => {
    if (!campaign) {
      return;
    }
    return compressedImageUrl(campaign.imageUrl, { width: 100 });
  }, [campaign]);

  const handleOnClick = useCallback(() => {
    if (!clickable || !campaign) {
      return;
    }
    if (typeof clickable === 'string') {
      window.location.href = clickable;
      return;
    }
    window.location.href = `#/campaigns/${campaign.id}/show`;
  }, [clickable, campaign]);

  if (!campaign) {
    return <></>;
  }

  return (
    <a
      style={{
        ...StyleSheet.container,
        cursor: clickable ? 'pointer' : 'inherit',
        ...style,
      }}
      onClick={handleOnClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img style={StyleSheet.image} src={imageUrl} />
      <div
        style={{
          ...StyleSheet.name,
          ...(isHovered && clickable ? StyleSheet.containerHovered : undefined),
        }}
      >
        {campaign.name}
      </div>
    </a>
  );
};

const StyleSheet: any = {
  container: {
    display: 'inline-flex',
    gap: 12,
    alignItems: 'center',
    textDecoration: 'none',
  },
  containerHovered: {
    textDecoration: 'underline',
  },
  image: {
    maxWidth: 50,
    maxHeight: 50,
    borderRadius: 100,
    boxShadow: '0px 0px 0 1px rgba(0,0,0,.05)',
  },
  name: {
    fontWeight: 600,
    color: 'black',
  },
};

export default CampaignBlock;
