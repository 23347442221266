import React, { useCallback } from 'react';
import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import { CampaignType } from 'types/campaign';
import { ActionType } from 'types/action';
import moment from 'moment-timezone';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { CampaignCauseList } from '@components/lists/campaign_cause_list';
import { AdminUserCampaignList } from '@components/lists/admin_user_campaigns_list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Calendar, { CalendarItems } from '@components/calendar';
import ActionCard from '@components/calendar/components/action_card';

import MissionsGroupedByStatus from '../missions/components/list/missions_grouped_by_status';

import CampaignBlock from './components/campaign_block';

const ShowActions = <ResourceTitleActionBar mode="show" />;

const CampaignShowComponent = () => {
  const campaign: CampaignType = useRecordContext();
  const { canCreate } = useRoleBasedPermissions();
  const dataProvider = useDataProvider();

  /**
   * Fetch calendar items
   * @param daysDisplayed
   * @returns {Promise<CalendarItems>}
   */
  const fetchCalendarItems = useCallback(
    async (daysDisplayed?: string[]) => {
      if (!campaign?.id || !daysDisplayed) {
        return {};
      }
      const { data } = await dataProvider.getList('actions', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'publishedAt', order: 'ASC' },
        filter: {
          campaignId: campaign.id,
          publishedAt: daysDisplayed,
        },
      });

      const result: CalendarItems = {};
      data.forEach((action: ActionType) => {
        const day = moment(action.publishedAt).format('YYYY-MM-DD');
        if (!day) {
          return;
        }
        if (!result[day]) {
          result[day] = [];
        }
        result[day].push(<ActionCard key={action.id} action={action} withMission />);
      });
      return result;
    },
    [dataProvider, campaign?.id]
  );

  return (
    <>
      <TabbedShowLayout>
        {/* Home */}
        <Tab label="Home">
          <CampaignBlock campaign={campaign} />
          <br />
          <Calendar fetchItems={fetchCalendarItems} />
          <MissionsGroupedByStatus campaignId={campaign?.id} />
        </Tab>
        {/* Details */}
        <Tab label="Details">
          <TextField
            source="descriptionEN"
            label="English description"
            fullWidth
            component="pre"
            style={{ whiteSpace: 'pre-wrap' }}
          />
          <br />
          <TextField
            source="descriptionFR"
            label="French description"
            fullWidth
            component="pre"
            style={{ whiteSpace: 'pre-wrap' }}
          />
          <hr style={{ width: '100%', height: 1 }} />
          <strong>Social links:</strong>
          <UrlField source="websiteUrl" />
          <UrlField source="instagramHandle" />
          <UrlField source="tiktokHandle" />
          <UrlField source="facebookHandle" />
          <UrlField source="twitterHandle" />
          <UrlField source="linkedinHandle" />
          <UrlField source="snapchatHandle" />
        </Tab>
        <Tab label="Causes" path="causes">
          <CampaignCauseList />
        </Tab>

        {canCreate('admin_users_campaigns') && (
          <Tab label="Managers" path="managers">
            <AdminUserCampaignList />
          </Tab>
        )}
      </TabbedShowLayout>
    </>
  );
};

export const CampaignShow = () => (
  <Show actions={ShowActions}>
    <CampaignShowComponent />
  </Show>
);
