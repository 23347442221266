import React, { useMemo } from 'react';
import {
  Button,
  Loading,
  RecordContextProvider,
  useGetOne,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useParams } from 'react-router-dom';
import { MdCampaign as CampaignIcon } from 'react-icons/md';
import { ActionType } from 'types/action';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ellipsis from '@services/ellipsis';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { ActionIcon, MissionIcon } from '@components/icons';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import { SpacingStyle } from '@styles/variables';

import { ActionCreateDialog } from './components/create_dialog';
import AnalyticsCard from './components/show/analytics_card';
import AvailableCountriesCard from './components/show/available_countries_card';
import CTACard from './components/show/cta_card';
import DescriptionsCard from './components/show/descriptions_card';
import MissionCard from './components/show/mission_card';
import OwnersCard from './components/show/owners_card';
import PointsCard from './components/show/points_card';
import PreviewCard from './components/show/preview_card';
import PriorityCard from './components/show/priority_card';
import PublishCard from './components/show/publish_card';
import DifficultyCard from './components/show/difficulty_card';

const ShowActionActions = () => {
  const action: ActionType = useRecordContext() as ActionType;
  const backLabel = action?.mission?.id
    ? 'Back to mission'
    : action?.campaign?.id
      ? 'Back to campaign'
      : null;
  const backTo = action?.mission?.id
    ? `#/missions/${action?.mission?.id}/show`
    : action?.campaign?.id
      ? `#/campaigns/${action?.campaign?.id}/show`
      : '#';

  return (
    <ResourceTitleActionBar
      mode="show"
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle[4],
          }}
        >
          <UpdateFieldDialog
            resource="actions"
            record={action}
            field="name"
            buttonIcon={<ActionIcon size={22} />}
            buttonLabel={ellipsis(action?.name || '', 50)}
          />
          {!action?.publishedAt && ' (draft)'}
        </div>
      }
      actions={
        <>
          <Button
            href={action?.publicUrl}
            label="Universal link"
            variant="outlined"
            target="_blank"
            startIcon={<IosShareIcon />}
          />
          {backLabel && (
            <Button
              href={backTo}
              label={backLabel}
              variant="outlined"
              startIcon={
                action?.mission?.id ? <MissionIcon size={14} /> : <CampaignIcon />
              }
            />
          )}
        </>
      }
    />
  );
};

/**
 * Displays a duplicate and a delete button block for super admins and campaign admins+managers for draft actions
 * @returns Delete button block
 */
const ActionsButtonBlock = () => {
  const action: ActionType = useRecordContext() as ActionType;
  const { canCreate: canCreateFromRPB, canDelete: canDeleteFromRBP } =
    useRoleBasedPermissions();
  const canDelete = useMemo(
    () => canDeleteFromRBP('actions') && !action?.publishedAt,
    [canDeleteFromRBP, action?.publishedAt]
  );

  const canDuplicate = useMemo(() => canCreateFromRPB('actions'), [canCreateFromRPB]);

  if (!canDelete && !canDuplicate) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gridColumn: 'span 2',
        margin: SpacingStyle.big,
        gap: SpacingStyle.normal,
      }}
    >
      {canDuplicate && (
        <ActionCreateDialog
          primaryOwnerId={action?.owners?.[0]?.id}
          missionId={action?.missionId}
          campaignId={action?.campaignId}
          defaultActionName={`${action?.name} (copy)`}
          descriptionFR={action?.descriptionFR}
          descriptionEN={action?.descriptionEN}
          publishedAt={action?.publishedAt}
          ctaType={action?.ctaType}
          points={action?.points}
          expiresIn={action?.expiresIn}
          priority={action?.priority}
          usersObjective={action?.usersObjective}
          buttonLabel="Duplicate"
          buttonIcon={<DuplicateIcon />}
          calloutText="All fields will be duplicated in a new action."
        />
      )}
      {canDelete && (
        <Button
          href={`#/actions/${action.id}`}
          label={`Delete this ${action.publishedAt ? 'action' : 'draft'}`}
          startIcon={<DeleteIcon />}
          variant="outlined"
          color="error"
        />
      )}
    </div>
  );
};

const ShowCardsLayout = () => {
  const isMediumScreen = useMediaQuery('(max-width: 900px)');
  return (
    <div
      style={{
        display: isMediumScreen ? 'block' : 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        width: '100%',
        maxWidth: 1200,
        marginBottom: 200,
      }}
    >
      <MissionCard />

      <OwnersCard />

      <CTACard />

      <DescriptionsCard />
      <PreviewCard />

      <PriorityCard />
      <DifficultyCard />
      <AvailableCountriesCard />
      <PointsCard />
      <PublishCard />
      <AnalyticsCard />
      <ActionsButtonBlock />
    </div>
  );
};

export const ActionShow = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(
    'actions',
    { id },
    { onError: () => redirect('/actions') }
  );
  if (isLoading) {
    return <Loading />;
  }
  return (
    <RecordContextProvider value={data}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ShowActionActions />
        <ShowCardsLayout />
      </div>
    </RecordContextProvider>
  );
};
