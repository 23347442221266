import * as React from 'react';
import { Edit, required, SimpleForm, TextInput } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

import { AdminUserRoleInput } from './components/roles';

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const AdminUserEdit = () => (
  <Edit actions={EditActions}>
    <SimpleForm>
      <TextInput source="username" fullWidth />
      <TextInput source="password" fullWidth />
      <CustomReferenceInput
        source="userId"
        reference="users"
        label="User"
        queryKey="username"
        validate={[required()]}
      />

      <AdminUserRoleInput />
    </SimpleForm>
  </Edit>
);
