import React, { useEffect, useMemo, useState } from 'react';
import { Button, useDataProvider, useRecordContext, useRefresh } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import PublishIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import moment from 'moment-timezone';
import { MissionType } from 'types/mission';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { dateFormatter } from '@services/date';
import TodoChecklist from '@components/todo_checklist';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import Callout from '@components/callout';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

const PublishCard = () => {
  const mission: MissionType = useRecordContext();
  const dataprovider = useDataProvider();
  const refresh = useRefresh();
  const { isSuperAdmin, canUpdate } = useRoleBasedPermissions();

  const [isThumbnailDefined, setIsThumbnailDefined] = useState(false);
  const [isCauseDefined, setIsCauseDefined] = useState(false);
  const [isOwnerDefined, setIsOwnerDefined] = useState(false);
  const [isCampaignDefined, setIsCampaignDefined] = useState(false);
  const [isStoryUploaded, setIsStoryUploaded] = useState(false);
  const [isFirstActionReady, setIsFirstActionReady] = useState(false);
  const [isTitleENDefined, setIsTitleENDefined] = useState(false);
  const [isTitleFRDefined, setIsTitleFRDefined] = useState(false);
  const [isLocationDefined, setIsLocationDefined] = useState(false);
  const [isDescriptionENDefined, setIsDescriptionENDefined] = useState(false);
  const [isDescriptionFRDefined, setIsDescriptionFRDefined] = useState(false);
  const [userTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    setIsThumbnailDefined(!!mission?.thumbnailUrl);
    setIsCauseDefined(!!mission?.causes?.[0]?.name);
    setIsOwnerDefined(!!mission?.ownerId);
    setIsCampaignDefined(!!mission?.campaignId);
    setIsStoryUploaded(!!mission?.stories?.[0]?.video?.videoUrl);
    setIsFirstActionReady(
      !!mission?.actions?.some(
        a => !!a.publishedAt && moment(a.publishedAt).isSameOrBefore(moment())
      )
    );
    setIsTitleENDefined(!!mission?.titleEN);
    setIsTitleFRDefined(!!mission?.titleFR);
    setIsLocationDefined(!!mission?.location);
    setIsDescriptionENDefined(!!mission?.descriptionEN);
    setIsDescriptionFRDefined(!!mission?.descriptionFR);
  }, [mission]);

  const isReadyToPublish = useMemo(() => {
    if (isSuperAdmin) {
      return true;
    }
    return (
      isThumbnailDefined &&
      isCauseDefined &&
      isOwnerDefined &&
      isCampaignDefined &&
      isStoryUploaded &&
      isFirstActionReady &&
      isTitleENDefined &&
      isTitleFRDefined &&
      isLocationDefined &&
      isDescriptionENDefined &&
      isDescriptionFRDefined
    );
  }, [
    isSuperAdmin,
    isThumbnailDefined,
    isCauseDefined,
    isOwnerDefined,
    isCampaignDefined,
    isStoryUploaded,
    isFirstActionReady,
    isTitleENDefined,
    isTitleFRDefined,
    isLocationDefined,
    isDescriptionENDefined,
    isDescriptionFRDefined,
  ]);

  const publishedInThePast = useMemo(() => {
    return (
      !!mission?.publishedAt && moment(mission?.publishedAt).diff(moment(), 'days') <= 0
    );
  }, [mission?.publishedAt]);

  /**
   * @returns list of missing properties
   */
  const MissingPropertiesBlock = () => {
    const messages = [];
    if (!isThumbnailDefined) {
      messages.push('A thumbnail');
    }
    if (!isCauseDefined) {
      messages.push('A cause');
    }
    if (!isOwnerDefined) {
      messages.push('A mission owner');
    }
    if (!isCampaignDefined) {
      messages.push('A campaign');
    }
    if (!isStoryUploaded) {
      messages.push('A first story');
    }
    if (!isFirstActionReady) {
      messages.push('A first action, live');
    }
    if (!isTitleENDefined) {
      messages.push('An English title');
    }
    if (!isDescriptionENDefined) {
      messages.push('An English description');
    }
    if (!isTitleFRDefined) {
      messages.push('A French title');
    }
    if (!isLocationDefined) {
      messages.push('A location');
    }
    if (!isDescriptionFRDefined) {
      messages.push('A French description');
    }
    if (messages.length === 0) {
      if (mission.publishedAt) {
        return <></>;
      }
      return (
        <div
          style={{
            fontSize: FontStyle.sizeSmall,
            fontWeight: 500,
            color: Colors.Magenta.primary,
          }}
        >
          Ready to be published!
        </div>
      );
    }
    return (
      <div
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          fontSize: FontStyle.sizeVerySmall,
          fontWeight: 500,
          color: Colors.Red.primary,
        }}
      >
        <div>Before publishing, you first need to define:</div>
        <ul style={{ margin: SpacingStyle[4] }}>
          {messages.map((message, i) => (
            <li key={i}>{message}</li>
          ))}
        </ul>
      </div>
    );
  };

  const UnpublishMission = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
      const confirmed = window.confirm(
        'Are you sure you want to unpublish this mission?\nIt will be removed from the app.'
      );
      if (!confirmed) {
        return;
      }
      try {
        setIsLoading(true);
        await dataprovider.update('missions', {
          id: mission.id,
          data: {
            publishedAt: null,
          },
        } as any);
        await refresh();
      } finally {
        setIsLoading(false);
      }
    };
    if (!mission?.publishedAt || mission.outcome || !canUpdate('missions')) {
      return <></>;
    }
    return (
      <Button
        variant="outlined"
        onClick={handleClick}
        label="Unpublish"
        endIcon={<EventBusyIcon style={{ width: '.8em' }} />}
        style={{
          fontSize: FontStyle.sizeVerySmall,
        }}
        color="error"
        disabled={isLoading}
      />
    );
  };

  const PublishedBlock = () => {
    const isPublishedAtInThePast = new Date(mission?.publishedAt) < new Date();
    return (
      <>
        <div style={{ fontSize: FontStyle.sizeSmall, fontWeight: '500' }}>
          {isPublishedAtInThePast ? 'Was published on' : 'Will be published on'}
        </div>
        <div
          style={{
            fontSize: FontStyle.sizeBig,
            fontWeight: 600,
            lineHeight: 1,
          }}
        >
          {dateFormatter(mission?.publishedAt, { short: true, withTime: true })}
        </div>
        <div
          style={{
            fontSize: FontStyle.sizeVerySmall,
            fontWeight: 500,
            color: Colors.Grey[500],
          }}
        >
          ({userTimezone})
        </div>
        <Callout emoji="🕰️" backgroundColor={Colors.Grey[25]}>
          <strong>Users will see this mission in the app at:</strong>
          <table>
            <tbody>
              <tr>
                <td>
                  • <strong>NYC</strong>
                </td>
                <td>
                  {moment(mission?.publishedAt)
                    .tz('America/New_York')
                    .format('dddd D MMM. @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>London</strong>
                </td>
                <td>
                  {moment(mission?.publishedAt)
                    .tz('Europe/London')
                    .format('dddd D MMM. @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>Paris</strong>
                </td>
                <td>
                  {moment(mission?.publishedAt)
                    .tz('Europe/Paris')
                    .format('dddd D MMM. @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>Sydney</strong>
                </td>
                <td>
                  {moment(mission?.publishedAt)
                    .tz('Australia/Sydney')
                    .format('dddd D MMM. @ hh:mma')}
                </td>
              </tr>
            </tbody>
          </table>
        </Callout>
      </>
    );
  };

  if (!mission) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader title="Publish" avatar={<PublishIcon />} />
      <CardContent>
        {/* publishedAt */}
        <TodoChecklist
          checked={!!mission?.publishedAt}
          label="Set a publication date"
          cta={
            !mission.outcome && (
              <div style={{ display: 'flex' }}>
                <UpdateFieldDialog
                  resource="missions"
                  record={mission}
                  type="date"
                  field="publishedAt"
                  formatter={date => moment(date).format('YYYY-MM-DD HH:mm:ss')}
                  buttonLabel={mission.publishedAt ? 'Edit' : 'Publish'}
                  buttonVariant="outlined"
                  disabled={
                    !isReadyToPublish || (publishedInThePast && !canUpdate('missions'))
                  }
                  confirmationMessage={
                    'Once published, you will not be able to change the publication date anymore.\nAre you sure you want to publish this mission?'
                  }
                />
                <UnpublishMission />
              </div>
            )
          }
        >
          {mission.publishedAt && <PublishedBlock />}
          <MissingPropertiesBlock />
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default PublishCard;
