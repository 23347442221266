import React from 'react';
import {
  Button,
  DateField,
  Labeled,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import LockIcon from '@mui/icons-material/Lock';
import { AdminUserType } from 'types/admin_user';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Callout from '@components/callout';

import { AdminUserCampaignsList } from '../admin_users_campaigns/components/admin_users_campaigns_list';

import { AdminUserRoleField } from './components/roles';

const ShowActions = () => {
  const dataProvider = useDataProvider();
  const adminUser: AdminUserType = useRecordContext();
  const refresh = useRefresh();

  const handleLogout = async () => {
    try {
      await dataProvider.logoutAdminUser({ adminUserId: adminUser.id });
      refresh();
      window.confirm('User logged out.');
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <ResourceTitleActionBar
      mode="show"
      actions={
        <Button
          variant="outlined"
          color="primary"
          label={'Log user out'}
          startIcon={<LockIcon />}
          onClick={handleLogout}
        />
      }
    />
  );
};

export const AdminUserShow = () => {
  const { canCreate } = useRoleBasedPermissions();
  return (
    <Show actions={<ShowActions />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="username" />
          <Labeled label="Role">
            <AdminUserRoleField />
          </Labeled>
          <ReferenceField source="userId" reference="users" link="show" label="User">
            <TextField source="username" />
          </ReferenceField>
          <DateField source="lastConnectedAt" showTime />
        </Tab>
        <Tab label="Campaigns">
          <AdminUserCampaignsList />
          {canCreate('admin_users_campaigns') && (
            <Callout backgroundColor="grey">
              To add or remove this admin from a campaign, go in the Campaign page you
              want and open the "Managers" tabs.
            </Callout>
          )}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
