import React, { useState } from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { UserType } from 'types/user';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import {
  AmplitudeButton,
  PushNotifyUserButton,
  UnsubscribeUserFromMission,
} from '@services/users';
import { UserFeatureFlagsCustomList } from '@components/lists/user_feature_flags_list';
import { UserFriendshipCustomList } from '@components/lists/user_friendships_list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { SpacingStyle } from '@styles/variables';

import UserSettingsNotificationTimeOfDay from './components/show/user_settings_notification_time_of_day';
import SpyTab from './components/show/spy_tab';
import SpyBanner from './components/show/spy_banner';
import ProfileTab from './components/show/profile_tab';
import UserTransactionsTab from './components/show/user_transaction_tab';
import { UndoUserAction } from './components/undo_user_action';

const UserShowActions = () => {
  const record = useRecordContext();

  return (
    <ResourceTitleActionBar
      mode="show"
      filters={
        <>
          <PushNotifyUserButton user={record} />
          <AmplitudeButton user={record} />
        </>
      }
    />
  );
};

const UserShowTabs = () => {
  const { canCreate } = useRoleBasedPermissions();

  const [userTopicsPage, setUserTopicsPage] = useState(1);
  const {
    data: userTopics,
    pageInfo: userTopicPageInfo,
    total: userTopicsTotal,
    isLoading: userTopicIsLoading,
  } = useGetList('users_topics', {
    pagination: { page: userTopicsPage, perPage: 50 },
    sort: { field: 'createdAt', order: 'DESC' },
    filter: {
      userId: useRecordContext<UserType>()?.id,
    },
  });

  return (
    <>
      <SpyBanner />
      <TabbedShowLayout>
        <Tab label="Profile">
          <ProfileTab />
        </Tab>
        <Tab label="Causes" path="causes">
          <ArrayField source="causes" label="Causes">
            <Datagrid bulkActionButtons={false}>
              <ReferenceField source="id" reference="causes" link="show" label={false} />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Missions" path="missions">
          <ArrayField source="missions" label="Missions joined">
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                source="id"
                reference="missions"
                link="show"
                label="Mission"
              />
              <DateField source="joinedAt" showTime />
              <FunctionField
                render={(record: any) => (
                  <UnsubscribeUserFromMission userMissionId={record.userMissionId} />
                )}
              />
            </Datagrid>
            <FunctionField
              render={(record: any) =>
                !canCreate('users_missions') && (
                  <Button
                    href={`#/users_missions/create?source={"userId":"${record.id}"}`}
                    startIcon={<AddIcon />}
                    label="Assign user to another mission"
                  />
                )
              }
            />
          </ArrayField>
        </Tab>
        <Tab label="Actions" path="actions">
          <NumberField source="points" label="Points earned" />
          <ArrayField source="actions" label="Completed actions">
            <Datagrid bulkActionButtons={false}>
              <ReferenceField source="campaignId" reference="campaigns" link="show" />
              <ReferenceField
                source="id"
                reference="actions"
                link="show"
                label="Action"
              />
              <DateField source="doneAt" showTime />
              <FunctionField
                render={(record: any) => (
                  <UndoUserAction userActionId={record.userActionId} />
                )}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Transactions" path="transactions">
          <UserTransactionsTab />
        </Tab>
        <Tab label="Topics" path="topics">
          <ArrayField source="topics">
            <Datagrid
              data={userTopics}
              total={userTopicsTotal}
              bulkActionButtons={false}
              rowClick={(_id, __, record) => `/topics/${record.topicId}/chat`}
            >
              <DateField source="createdAt" showTime sortable={false} />
              <ReferenceField source="topicId" reference="topics" link={false} />
            </Datagrid>

            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: SpacingStyle.normal,
              }}
            >
              <Button
                label="Previous page"
                variant="outlined"
                onClick={() => {
                  setUserTopicsPage(userTopicsPage - 1);
                }}
                disabled={userTopicsPage <= 1 || userTopicIsLoading}
              />
              <p>
                <strong>{userTopicsPage}</strong>
                <small>
                  {' / '}
                  {Math.ceil((userTopicsTotal || 0) / 50)}
                </small>
              </p>
              <Button
                label="Next page"
                variant="outlined"
                onClick={() => {
                  setUserTopicsPage(userTopicsPage + 1);
                }}
                disabled={userTopicPageInfo?.hasNextPage === false || userTopicIsLoading}
              />
            </div>
          </ArrayField>
        </Tab>
        <Tab label="Friends" path="friends">
          <UserFriendshipCustomList />
        </Tab>
        <Tab label="Badges">
          {!canCreate('users_badges') && (
            <FunctionField
              render={(record: UserType) => (
                <Button
                  href={`#/users_badges/create?source={"userId":"${record?.id}"}`}
                  label={`Add a badge to ${record?.username}`}
                  startIcon={<AddIcon />}
                />
              )}
            />
          )}
          <ArrayField source="badges" label="Badges">
            <Datagrid
              bulkActionButtons={false}
              rowClick={(_id, _resource, record) =>
                `/users_badges/${record.userBadgeId}/show`
              }
            >
              <ReferenceField source="id" reference="badges" link="show" label="Name" />
              <DateField source="seenAt" showTime />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Notifications" path="notifications">
          <ArrayField source="notifications">
            <Datagrid bulkActionButtons={false}>
              <div style={{ width: 0 }}>✅</div>
              <TextField source="type" />
              <UserSettingsNotificationTimeOfDay source="timeOfDay" />
              <DateField showTime source="createdAt" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="expoPushTokens">
            <Datagrid bulkActionButtons={false}>
              <TextField source="pushToken" />
              <DateField showTime source="createdAt" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Feature Flags" path="feature_flags">
          <UserFeatureFlagsCustomList />
          {canCreate('users_feature_flags') && (
            <FunctionField
              render={(record: UserType) => (
                <Button
                  href={`#/users_feature_flags/create?source={"userId":"${record.id}"}`}
                  startIcon={<AddIcon />}
                  label="Add a Feature Flag"
                />
              )}
            />
          )}
        </Tab>
        <Tab label="Spy detection" path="spy_detection">
          <SpyTab />
        </Tab>
      </TabbedShowLayout>
    </>
  );
};

export const UserShow = () => (
  <Show actions={<UserShowActions />}>
    <UserShowTabs />
  </Show>
);
