import React, { useState } from 'react';
import { Button, useDataProvider } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CampaignType } from 'types/campaign';
import { MissionType } from 'types/mission';
import { ActionType } from 'types/action';
import { UserType } from 'types/user';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import Callout from '@components/callout';
import InputStyle from '@styles/input';

type ActionCreateDialogProps = {
  campaignId?: CampaignType['id'];
  missionId?: MissionType['id'];
  primaryOwnerId?: UserType['id'];
  defaultActionName?: string;
  descriptionFR?: ActionType['descriptionFR'];
  descriptionEN?: ActionType['descriptionEN'];
  publishedAt?: ActionType['publishedAt'];
  ctaType?: ActionType['ctaType'];
  points?: ActionType['points'];
  expiresIn?: ActionType['expiresIn'];
  priority?: ActionType['priority'];
  usersObjective?: ActionType['usersObjective'];
  buttonLabel?: string;
  buttonIcon?: React.ReactNode;
  calloutText?: string;
};
export const ActionCreateDialog = ({
  campaignId,
  missionId,
  primaryOwnerId,
  defaultActionName = '',
  descriptionFR,
  descriptionEN,
  publishedAt,
  ctaType,
  points,
  expiresIn,
  priority,
  usersObjective,
  buttonLabel = 'Create a draft action',
  buttonIcon = <AddIcon />,
  calloutText = 'Giving a clear name to your action will help you find it later more easily.',
}: ActionCreateDialogProps) => {
  const dataProvider = useDataProvider();
  const { canCreate } = useRoleBasedPermissions();

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [actionName, setActionName] = useState(defaultActionName);
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setIsSubmitting(false);
  };

  const handleClose = () => {
    if (isSubmitting) {
      window.alert("You can't close this modal while the action is being saved.");
      return;
    }
    setOpen(false);
  };

  /**
   * Create an action and redirect to its show page.
   */
  const createAction = async () => {
    setIsSubmitting(true);
    try {
      const { data: action } = await dataProvider.create('actions', {
        data: {
          name: actionName,
          campaignId,
          missionId,
          descriptionFR,
          descriptionEN,
          publishedAt,
          ctaType,
          points,
          expiresIn,
          priority,
          usersObjective,
        },
      });
      if (primaryOwnerId) {
        await dataProvider.create('actions_owners', {
          data: {
            actionId: action.id,
            ownerId: primaryOwnerId,
          },
        });
      }
      window.location.href = `#/actions/${action.id}/show`;
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!canCreate('actions')) {
    return null;
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        label={buttonLabel}
        startIcon={buttonIcon}
      />
      <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
        <DialogTitle>What is the action about?</DialogTitle>
        <DialogContent>
          <Callout backgroundColor="grey" emoji="👇">
            {calloutText}
          </Callout>
          <input
            type="text"
            autoFocus
            style={InputStyle.input}
            placeholder="ex: Write an email to BlackRock CEO"
            value={actionName}
            onChange={e => setActionName(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                createAction();
              }
            }}
          />
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={createAction}
            label={isSubmitting ? 'Creating...' : 'Create'}
            disabled={isSubmitting || actionName.length < 4}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
