import React from 'react';
import { SelectInput, Validator } from 'react-admin';

type BadgeTypeInputProps = {
  validate?: Validator | Validator[] | undefined;
};
const BadgeTypeInput = ({ validate }: BadgeTypeInputProps) => (
  <SelectInput
    source="type"
    choices={[
      { id: 'ACTION', name: 'Action' },
      { id: 'CAMPAIGN', name: 'Campaign' },
      { id: 'CUSTOM', name: 'Custom' },
      { id: 'DONATION', name: 'Donation' },
      { id: 'FRIENDSHIP', name: 'Friendship' },
      { id: 'MISSION', name: 'Mission' },
      { id: 'PRODUCT_DISCOVERY', name: 'Product discovery' },
      { id: 'STREAK', name: 'Streak' },
    ]}
    fullWidth
    validate={validate}
  />
);

export default BadgeTypeInput;
