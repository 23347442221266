import * as React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import { AdminUserRole } from 'types/admin_user_role.d';

import ellipsis from '@services/ellipsis';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { PER_PAGE_OPTIONS } from '@components/list';
import { FontStyle } from '@styles/variables';

import { AdminUserRoleField } from './components/roles';

const ListActions = <ResourceTitleActionBar mode="list" />;

export const AdminUserList = () => (
  <List
    exporter={false}
    sort={{ field: 'username', order: 'ASC' }}
    perPage={150}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <FunctionField
        label=""
        render={(record: any) => {
          const isChilliTeam =
            record.role === AdminUserRole.super_admin ||
            record.role === AdminUserRole.campaign_admin ||
            record.role === AdminUserRole.product;

          const connectedAtLeastOnce = !!record.lastConnectedAt;
          const hasCampaign = record.campaigns && record.campaigns.length > 0;
          const hasUser = !!record.userId;

          if (connectedAtLeastOnce && (hasCampaign || isChilliTeam) && hasUser) {
            return '✅';
          }
          return '❌';
        }}
      />
      <TextField source="username" />
      <AdminUserRoleField source="role" />
      <DateField source="createdAt" />
      <FunctionField
        label="Last login"
        render={(record: any) => {
          if (!record.lastConnectedAt) {
            return (
              <div style={{ fontSize: FontStyle.sizeVerySmall, color: 'red' }}>never</div>
            );
          }
          return <DateField source="lastConnectedAt" />;
        }}
      />
      <FunctionField
        source="campaigns"
        render={(record: any) => {
          if (
            record.role === AdminUserRole.super_admin ||
            record.role === AdminUserRole.campaign_admin ||
            record.role === AdminUserRole.product
          ) {
            return <div style={{ fontSize: FontStyle.sizeVerySmall }}>all</div>;
          }
          if (!record.campaigns || record.campaigns.length === 0) {
            return (
              <div style={{ fontSize: FontStyle.sizeVerySmall, color: 'red' }}>none</div>
            );
          }
          return (
            <>
              {record.campaigns.map((campaign: any) => (
                <div key={campaign.id} style={{ fontSize: FontStyle.sizeVerySmall }}>
                  - {ellipsis(campaign.name, 40)}
                </div>
              ))}
            </>
          );
        }}
      />
      <FunctionField
        source="userId"
        render={(record: any) => {
          if (!record.userId) {
            return (
              <div style={{ fontSize: FontStyle.sizeVerySmall, color: 'red' }}>
                not associated to a user
              </div>
            );
          }
          return (
            <ReferenceField source="userId" reference="users" link="show" label="User">
              <TextField source="username" />
            </ReferenceField>
          );
        }}
      />
    </Datagrid>
  </List>
);
