import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { MissionType, OutcomeStatus } from 'types/mission.d';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { dateFormatter } from '@services/date';
import { MissionIcon } from '@components/icons';
import TodoChecklist from '@components/todo_checklist';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import { FontStyle } from '@styles/variables';

export const selectOutcomeChoices = [
  { id: '', disabled: true, selected: true, name: 'Select an outcome' },
  { id: OutcomeStatus.SUCCESS, name: '🎉 Mission won + alert members' },
  { id: OutcomeStatus.FAILURE, name: '😭 Mission lost + alert members' },
  // { id: OutcomeStatus.ON_HOLD, name: 'Mission on hold' },
  { id: OutcomeStatus.CLOSED, name: '🔒 Mission secretly closed' },
];

const OutcomeCard = () => {
  const mission: MissionType = useRecordContext();
  const { canUpdate } = useRoleBasedPermissions();

  /**
   * Display the outcome status of the mission
   * @returns {string}
   */
  const outcomeStatus = useMemo(() => {
    if (!mission.outcome) {
      return 'No outcome set yet.';
    }
    let outcome = `${mission.outcome}`;
    if (mission.outcome === OutcomeStatus.SUCCESS) {
      outcome = '🎉 Mission won';
    }
    if (mission.outcome === OutcomeStatus.FAILURE) {
      outcome = '😭 Mission lost';
    }
    if (mission.outcome === OutcomeStatus.ON_HOLD) {
      outcome = '⏸️ Mission on hold';
    }
    if (mission.outcome === OutcomeStatus.CLOSED) {
      outcome = '🔒 Mission closed';
    }
    if (mission.outcomeSetAt) {
      return `${outcome} on ${dateFormatter(mission.outcomeSetAt, { withTime: true })}`;
    }
    return outcome;
  }, [mission.outcome, mission.outcomeSetAt]);

  if (!mission.publishedAt) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader title="Outcome" avatar={<MissionIcon />} />
      <CardContent>
        <TodoChecklist
          checked={!!mission?.outcomeSetAt}
          label="End the mission"
          cta={
            canUpdate('missions') && (
              // !mission.outcome &&
              <UpdateFieldDialog
                resource="missions"
                record={mission}
                type="select"
                field="outcome"
                buttonLabel={
                  !mission?.outcomeSetAt ? 'Set an outcome' : 'Edit the outcome'
                }
                buttonVariant="outlined"
                selectOptions={selectOutcomeChoices}
                confirmationMessage="Are you sure you want to set the outcome of this mission? You won't be able to add or edit actions anymore."
              />
            )
          }
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              fontSize: FontStyle.sizeSmall,
            }}
          >
            {outcomeStatus}
          </div>
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default OutcomeCard;
