import React, { useState } from 'react';
import {
  Button,
  Create,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AdminUserRole } from 'types/admin_user_role.d';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Callout from '@components/callout';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

import { AdminUserRoleInput } from './components/roles';

const redirectAfterCreate = (_: any, __: any, data: any) => `admin_users/${data.id}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const parseUsername = (value: any) => {
  if (!value) {
    return null;
  }
  return value.replaceAll(/[^a-zA-Z0-9]/g, '_').toLowerCase();
};

// generate a random password with [0-9a-zA-Z] of 10 characters:
const password = Math.random().toString(36).slice(-10);

export const AdminUserCreate = () => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [username, setUsername] = useState('');

  const FormToolbar = () => (
    <Toolbar>
      <Button
        color="primary"
        size="medium"
        startIcon={<ContentCopyIcon />}
        onClick={() => {
          navigator.clipboard.writeText(`\
https://command-center.chilli.club
username: ${username}
password: ${password}
`);
          setCopiedToClipboard(true);
        }}
        label={copiedToClipboard ? 'Copy info again' : 'Copy info to clipboard'}
        variant="outlined"
      />
      {copiedToClipboard && <SaveButton type="button" />}
    </Toolbar>
  );

  return (
    <Create actions={CreateActions} redirect={redirectAfterCreate}>
      <SimpleForm toolbar={<FormToolbar />}>
        <TextInput
          source="username"
          fullWidth
          required
          autoFocus
          parse={parseUsername}
          onChange={e => setUsername(parseUsername(e.target.value || ''))}
        />
        <TextInput source="password" fullWidth defaultValue={password} required />
        <AdminUserRoleInput defaultValue={AdminUserRole.campaign_manager} />
        <CustomReferenceInput
          source="userId"
          reference="users"
          label="User"
          queryKey="username"
          validate={[required()]}
        />
        <Callout emoji="🕵️‍♂️" backgroundColor="grey">
          <p>
            <strong>Important:</strong> Password is displayed only once and cannot be
            retrieved later.
          </p>
          <p>
            Please <strong>copy info to clipboard</strong> first before being able to save
            the user.
          </p>
        </Callout>
      </SimpleForm>
    </Create>
  );
};
