import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';

const AmplitudeGraphCard = () => {
  const { isSuperAdmin, isCampaignAdmin, isProduct } = useRoleBasedPermissions();
  const canView = useMemo(
    () => isSuperAdmin || isCampaignAdmin || isProduct,
    [isSuperAdmin, isCampaignAdmin, isProduct]
  );

  if (!canView) {
    return null;
  }
  return (
    <Card>
      <CardContent>
        <iframe
          className="frame"
          src="https://app.amplitude.com/analytics/share/embed/fa100a66-9f01-4776-95c4-e6ec953a619b"
          frameBorder="0"
          width="100%"
          height="450"
        ></iframe>
      </CardContent>
    </Card>
  );
};

export default AmplitudeGraphCard;
