import React, { useCallback, useState } from 'react';
import { DonationType } from 'types/donation';

import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

type DonationCardProps = {
  donation: DonationType;
};

const DonationCard = ({ donation }: DonationCardProps) => {
  const [hovered, setHovered] = useState<boolean>(false);

  /**
   * Go to the donation page
   */
  const goToDonation = useCallback(() => {
    window.location.href = `#/donations/${donation.id}/show`;
  }, [donation.id]);

  return (
    <div
      style={{
        ...styles.container,
        ...(hovered ? styles.containerHovered : null),
      }}
      onClick={goToDonation}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div style={styles.typeContainer}>donation</div>
      <div style={styles.avatarContainer}>
        <img src={donation.avatarUrl} style={styles.avatar} />
      </div>

      <div style={styles.name}>{donation.nameEN}</div>
    </div>
  );
};

const styles: any = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: SpacingStyle[4],
    border: `1px solid ${Colors.Black.transparent.max}`,
    borderRadius: BorderStyle.Radius.small,
    overflow: 'hidden',
    userSelect: 'none',
    backgroundColor: Colors.White.primary,
  },
  containerHovered: {
    boxShadow: `0 1px 4px ${Colors.Black.transparent.max}`,
    border: `1px solid ${Colors.Black.transparent.medium}`,
    cursor: 'pointer',
  },
  typeContainer: {
    position: 'absolute',
    top: SpacingStyle[4],
    right: SpacingStyle[4],
    padding: `0 ${SpacingStyle[4]}px`,
    backgroundColor: Colors.Grey.primary,
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: '600',
    color: Colors.White.primary,
    borderRadius: BorderStyle.Radius.small,
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    objectFit: 'cover',
    backgroundColor: Colors.White.primary,
  },
  name: {
    marginTop: SpacingStyle[4],
    fontSize: FontStyle.sizeSmall,
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default DonationCard;
