import React, { useCallback } from 'react';
import {
  Button,
  useDataProvider,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { SpacingStyle } from '@styles/variables';

export const AdminUserCampaignList = () => {
  const { id, managers: adminUsers } = useRecordContext();
  const { canCreate, canDelete } = useRoleBasedPermissions();

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleItemClick = useCallback(
    (index: number) => {
      redirect('show', 'admin_users', adminUsers[index].id);
    },
    [adminUsers, redirect]
  );

  const handleRemoveManager = useCallback(
    async (index: number) => {
      await dataProvider.deleteJunctionRecord(
        'admin_users_campaigns',
        'admin_users',
        adminUsers[index].id,
        'campaigns',
        id
      );

      refresh();
    },
    [adminUsers, dataProvider, id, refresh]
  );

  return (
    <div>
      {canCreate('admin_users_campaigns') && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: SpacingStyle.big,
            columnGap: SpacingStyle.normal,
          }}
        >
          <Button
            href={`#/admin_users_campaigns/create?campaignId=${id}`}
            label="Add another manager"
            startIcon={<AddIcon />}
          />
        </div>
      )}
      <table width={'100%'} style={{ borderSpacing: 0 }}>
        <thead>
          <tr style={{ textAlign: 'left', backgroundColor: '#d5d5d5' }}>
            <th>username</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adminUsers &&
            adminUsers.map((manager: any, i: number) => (
              <tr
                key={i}
                style={{
                  backgroundColor: i % 2 === 1 ? '#f4f4f6' : undefined,
                  cursor: 'pointer',
                }}
              >
                <td style={{ fontSize: '.8em' }} onClick={() => handleItemClick(i)}>
                  <code>{manager.username}</code>
                </td>
                <td
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    columnGap: SpacingStyle.big,
                  }}
                >
                  <Button
                    href={`#/admin_users/${manager.id}/show`}
                    label="View Manager"
                    startIcon={<VisibilityIcon />}
                  />
                  {canDelete('admin_users_campaigns') && (
                    <Button
                      style={{ color: 'red' }}
                      onClick={() => handleRemoveManager(i)}
                      label="Remove from campaign"
                      startIcon={<DeleteIcon />}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
