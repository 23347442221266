import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment-timezone';
import { MissionType } from 'types/mission';
import { ActionType } from 'types/action';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ellipsis from '@services/ellipsis';
import { compressedImageUrl } from '@modules/cloudinary';
import { CauseIcon, UserIcon } from '@components/icons';
import ActionCTATypeChip from '@components/action_cta_type_chip';
import CauseChip from '@models/causes/components/chip';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

/**
 * Sort the actions by publishedAt date
 * @param a
 * @param b
 * @returns {number}
 */
const sortActions = (a: ActionType, b: ActionType) => {
  if (!a.publishedAt) {
    return -1;
  }
  if (!b.publishedAt) {
    return 1;
  }
  return b.publishedAt.localeCompare(a.publishedAt);
};

type MissionCardProps = {
  mission: MissionType;
  language?: 'EN' | 'FR';
  isClickable?: boolean;
  withActions?: 'all' | 'published' | 'none';
  withStartedAt?: boolean;
  withUsersCount?: boolean;
  maxWith?: number | string;
  style?: any;
};
const MissionCard = ({
  mission,
  withActions = 'none',
  isClickable = true,
  withStartedAt = false,
  withUsersCount = false,
  maxWith,
  style,
}: MissionCardProps) => {
  const { canCreate } = useRoleBasedPermissions();
  const [hovered, setHovered] = useState(false);
  const [hoveredActionId, setHoveredActionId] = useState<ActionType['id'] | null>(null);

  const handleOnClick = () => {
    if (isClickable) {
      window.location.href = `#/missions/${mission.id}/show`;
    }
  };

  /**
   * Display the mission name in the selected language
   */
  const missionName = useMemo(() => {
    return mission.name || mission.titleEN || mission.titleFR;
  }, [mission]);

  /**
   * If the mission has no causes, we display a button to add a cause.
   * Otherwise, we display the causes.
   */
  const causes = useMemo(() => {
    if (mission.causes?.length === 0 && canCreate('missions_causes')) {
      const newMissionUrl = `#/missions_causes/create?source={"missionId":"${mission.id}"}`;
      return (
        <Button
          label="Add cause"
          variant="contained"
          href={newMissionUrl}
          style={{
            margin: 0,
            color: Colors.Magenta.primary,
            backgroundColor: Colors.Magenta[100],
          }}
          startIcon={<CauseIcon size={16} />}
        />
      );
    }
    return (
      <>
        {mission.causes?.map(cause => (
          <CauseChip key={cause?.id} cause={cause} clickable="missions" />
        ))}
      </>
    );
  }, [mission.causes, mission.id, canCreate]);

  /**
   * Display the mission story if there is one.
   */
  const missionStory = useMemo(() => {
    if (
      !mission.stories ||
      mission.stories?.length === 0 ||
      !mission?.stories[0].video?.posterUrl
    ) {
      return (
        <a
          href={`#/missions_stories/video_studio?missionId=${mission.id}`}
          style={styles.missionStory}
        >
          <AddIcon htmlColor={Colors.White.primary} />{' '}
        </a>
      );
    }
    const posterUrl = compressedImageUrl(mission?.stories[0].video?.posterUrl, {
      width: 50,
    });
    return (
      <img src={posterUrl} style={styles.missionStory} title="Mission first story" />
    );
  }, [mission.id, mission.stories]);

  /**
   * URL of the mission thumbnail
   */
  const thumbnailUrl = useMemo(() => {
    return compressedImageUrl(mission.thumbnailUrl, { width: 200 });
  }, [mission.thumbnailUrl]);

  /**
   * URL of the owner sticker
   */
  const ownerStickerUrl = useMemo(() => {
    if (!mission?.owner?.avatarNoBackgroundUrl) {
      return;
    }
    return compressedImageUrl(mission?.owner?.avatarNoBackgroundUrl, {
      width: 80,
    });
  }, [mission?.owner?.avatarNoBackgroundUrl]);

  /**
   * Color of the label displaying when the mission was published
   */
  const publishedAtLabelColor = useMemo(() => {
    if (!mission.publishedAt) {
      return Colors.Magenta[1000];
    }
    if (moment(mission.publishedAt).isAfter(moment())) {
      return Colors.Grey.primary;
    }

    const diff = moment().diff(moment(mission.publishedAt), 'days');
    if (diff > 35) {
      return Colors.Magenta.primary;
    }
    if (diff > 28) {
      return Colors.Red.primary;
    } else if (diff > 21) {
      return Colors.Orange[500];
    } else if (diff > 14) {
      return Colors.Yellow[400];
    }
    return Colors.Black.primary;
  }, [mission.publishedAt]);

  /**
   * Filter the actions to display based on the `withActions` prop
   */
  const actions = useMemo(() => {
    if (withActions === 'none' || !mission || !mission.actions) {
      return [];
    }
    if (withActions === 'all') {
      return mission.actions;
    }
    return mission.actions.filter(action => !!action.publishedAt);
  }, [withActions, mission]);

  /**
   * Render an action
   */
  const renderAction = useCallback(
    (action: ActionType) => {
      const isExpired = moment(action.publishedAt)
        .add(action.expiresIn, 'day')
        .isBefore(moment(), 'minute');
      return (
        <div key={action.id} style={styles.actionContainer}>
          <div style={styles.actionPublishedAt}>
            {!action.publishedAt ? 'Draft' : `${moment(action.publishedAt).fromNow()}`}
            {isExpired && ' (expired)'}
          </div>
          <a
            style={{
              ...styles.action,
              ...(hoveredActionId === action.id && styles.actionHovered),
              ...(!action.publishedAt && styles.actionDraft),
            }}
            href={`#/actions/${action.id}/show`}
            onClick={event => event.stopPropagation()}
            onMouseEnter={() => setHoveredActionId(action.id)}
            onMouseLeave={() => setHoveredActionId(null)}
          >
            <div style={{ ...(isExpired && styles.actionExpired) }}>
              {ellipsis(action.name || action.descriptionEN || 'no name', 40)}
            </div>
            <div style={styles.actionFooter}>
              <div style={styles.actionUsersCount}>
                {(action.usersCount || 0) > 0 && (
                  <>
                    <UserIcon size={FontStyle.sizeSmall} />
                    {action.usersCount}
                  </>
                )}
              </div>
              {action.ctaType && (
                <ActionCTATypeChip ctaType={action.ctaType} size="small" />
              )}
            </div>
          </a>
        </div>
      );
    },
    [hoveredActionId]
  );

  return (
    <div
      style={{
        ...styles.container,
        maxWidth: maxWith ? maxWith : styles.container.maxWidth,
        ...style,
      }}
      onClick={handleOnClick}
    >
      <div
        style={{
          ...styles.thumbnail,
          cursor: isClickable ? 'pointer' : 'default',
          backgroundImage: `url(${thumbnailUrl})`,
        }}
      >
        {withStartedAt && (
          <div
            style={{
              ...styles.thumbnailStartedAt,
              backgroundColor: publishedAtLabelColor,
            }}
          >
            {mission.publishedAt
              ? moment(mission.publishedAt).isAfter(moment())
                ? `starting ${moment(mission.publishedAt).fromNow()}`
                : `started ${moment().diff(
                    moment(mission.publishedAt),
                    'weeks'
                  )} weeks ago`
              : 'draft'}
          </div>
        )}
      </div>
      <div
        style={{
          ...styles.content,
          ...(hovered &&
            isClickable && { boxShadow: `0px 1px 4px ${Colors.Magenta[300]}` }),
          cursor: isClickable ? 'pointer' : 'default',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {mission?.owner?.avatarNoBackgroundUrl && (
          <img style={styles.ownerSticker} src={ownerStickerUrl} />
        )}
        {missionStory}
        {causes}

        <div style={styles.title}>{missionName}</div>
        {actions?.sort(sortActions).map(renderAction)}
        <div style={styles.footer}>
          {withUsersCount && (
            <div style={styles.usersCount}>
              <UserIcon size={FontStyle.sizeSmall} />
              {mission.usersCount || 0}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: 340,
  },
  thumbnail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 100,
    width: '94%',
    marginBottom: -14,
    marginLeft: '5%',
    flex: 1,
    backgroundColor: Colors.Magenta[200],
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    transform: 'rotate(3deg)',
    borderRadius: BorderStyle.Radius.small,
    border: `2px solid ${Colors.White.primary}`,
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.1)',
  },
  thumbnailStartedAt: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: `${SpacingStyle[4]}px ${SpacingStyle.small}px`,
    backgroundColor: Colors.Black.primary,
    color: Colors.White.primary,
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 600,
    borderRadius: `0 ${BorderStyle.Radius.tiny}px 0 ${BorderStyle.Radius.tiny}px`,
    boxShadow: '-2px 2px 2px 0 rgba(0, 0, 0, 0.25)',
    textAlign: 'right',
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: SpacingStyle[4],
    backgroundColor: Colors.White.transparent.light,
    backdropFilter: 'blur(20px)',
    border: `1px solid ${Colors.White.primary}`,
    borderRadius: BorderStyle.Radius.normal,
    padding: SpacingStyle.normal,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
    zIndex: 10,
  },
  ownerSticker: {
    position: 'absolute',
    top: -60,
    left: 4,
    width: 80,
    height: 80,
    objectFit: 'contain',
    filter: 'grayscale(100%)',
    transform: 'rotate(-3deg)',
  },
  missionStory: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    borderRadius: '100%',
    border: `2px solid ${Colors.White.primary}`,
    objectFit: 'cover',
    backgroundColor: Colors.Magenta.primary,
  },
  title: {
    flex: 1,
    lineHeight: 1.2,
    fontSize: FontStyle.sizeNormal,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
    textAlign: 'center',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: SpacingStyle.small,
  },
  action: {
    padding: 6,
    borderRadius: BorderStyle.Radius.small,
    border: `1px solid ${Colors.Grey[100]}`,
    width: '100%',
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 500,
    textDecoration: 'none',
    color: Colors.OffBlack.primary,
    backgroundColor: '#FFFEFF',
  },
  actionHovered: {
    border: `1px solid ${Colors.Grey[200]}`,
    backgroundColor: '#FDFBFD',
  },
  actionDraft: {
    opacity: 0.3,
    filter: 'grayscale(100%)',
  },
  actionFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: SpacingStyle[4],
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 700,
    color: Colors.Grey[300],
  },
  actionUsersCount: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
  },
  actionPublishedAt: {
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 500,
    color: Colors.Grey[300],
  },
  actionExpired: {
    textDecoration: 'line-through',
    color: Colors.Grey[300],
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  usersCount: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    color: Colors.Grey.primary,
  },
};

export default MissionCard;
