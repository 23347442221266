import React from 'react';
import CRMTag from '@mui/icons-material/Style';
import Info from '@mui/icons-material/Info';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { BiSolidBusiness } from 'react-icons/bi';
import { BsFillPersonVcardFill } from 'react-icons/bs';
import { FaHandFist } from 'react-icons/fa6';
import { FaHashtag } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { FaBuildingNgo } from 'react-icons/fa6';
import { IoAnalytics, IoFlagSharp } from 'react-icons/io5';
import { MdAnalytics } from 'react-icons/md';
import { MdCampaign } from 'react-icons/md';
import { MdWebStories } from 'react-icons/md';
import { RiAdminFill } from 'react-icons/ri';
import { TbTargetArrow } from 'react-icons/tb';
import { BiSolidBadge } from 'react-icons/bi';
import { CiPill } from 'react-icons/ci';
import { MdNotificationsActive } from 'react-icons/md';

import Favicon from './svgs/logos/favicon';

const DEFAULT_SIZE = 24;

type CloneIconProps = {
  icon: React.ReactElement;
  size?: number;
  color?: string;
};

const CloneIcon = ({ icon, size = DEFAULT_SIZE, color }: CloneIconProps) =>
  React.cloneElement(icon, {
    width: size,
    height: size,
    color: color || 'currentColor',
    style: {
      fontSize: size,
      width: `${size}px !important`,
      height: `${size}px !important`,
      color: color || 'currentColor',
    },
  });

type IconProps = {
  size?: number;
  color?: string;
};

export const InfoIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<Info />} size={size} color={color} />
);
export const CampaignIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<MdCampaign />} size={size} color={color} />
);
export const CRMPersonIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<BsFillPersonVcardFill />} size={size} color={color} />
);
export const CRMOrganizationIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<BiSolidBusiness />} size={size} color={color} />
);
export const CRMTagIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<CRMTag />} size={size} color={color} />
);
export const AdminUserIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<RiAdminFill />} size={size} color={color} />
);
export const ActivistIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<FaHandFist />} size={size} color={color} />
);
export const FeatureFlagIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<IoFlagSharp />} size={size} color={color} />
);
export const UserIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<FaUsers />} size={size} color={color} />
);
export const CampaignAnalyticsIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<MdAnalytics />} size={size} color={color} />
);
export const InAppNotificationsIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<MdNotificationsActive />} size={size} color={color} />
);
export const ActionIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<Favicon />} size={size} color={color} />
);
export const MissionIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<TbTargetArrow />} size={size} color={color} />
);
export const CauseIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<LoyaltyIcon />} size={size} color={color} />
);
export const StoryIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<MdWebStories />} size={size} color={color} />
);
export const TopicIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<FaHashtag />} size={size} color={color} />
);
export const AnalyticsIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<IoAnalytics />} size={size} color={color} />
);
export const BadgeIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<BiSolidBadge />} size={size} color={color} />
);
export const PillIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<CiPill />} size={size} color={color} />
);
export const DonationIcon = ({ size, color }: IconProps) => (
  <CloneIcon icon={<FaBuildingNgo />} size={size} color={color} />
);
